import { EnrichmentTag, ContextConfig } from '@uniformdev/context-ui';
import { EnrichmentData } from '@uniformdev/context';
import { FieldConnector } from '@contentful/field-editor-shared';
import { FieldProps } from '../../types/FieldProps';

const EnrichmentTagCustomField = function ({ sdk, config }: FieldProps & { config: ContextConfig }) {
  return (
    <FieldConnector<EnrichmentData[]> field={sdk.field} isInitiallyDisabled={false}>
      {({ value, setValue }) => {
        return (
          <EnrichmentTag
            contextConfig={{
              projectId: config.projectId,
              apiHost: config.apiHost,
              apiKey: config.apiKey!,
            }}
            setValue={setValue}
            value={value}
            displayTitle={false}
          />
        );
      }}
    </FieldConnector>
  );
};

export default EnrichmentTagCustomField;
