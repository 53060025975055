import { SpaceAPI, ContentType } from '@contentful/app-sdk';

export interface ContentTypes {
  items: ContentType[];
}

export const getContentTypes = async (api: SpaceAPI): Promise<ContentTypes> => {
  const contentTypes = await api.getContentTypes();
  return { items: contentTypes.items };
};
