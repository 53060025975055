export const parseError = (error: any): string => {
  if (error.message && error.code) {
    return `${error.message}: ${error.code}`;
  }

  if (error.message) {
    return error.message;
  }

  return error.toString();
};
