import { SpaceAPI } from '@contentful/app-sdk';
import { useEffect, useState } from 'react';
import { getContentTypes, ContentTypes } from '../../../api/getContentTypes';

interface UseContentTypesResult {
  data?: ContentTypes;
  refresh: () => void;
}

export const useContentTypes = (sdk: SpaceAPI): UseContentTypesResult => {
  const [value, setValue] = useState<ContentTypes | undefined>();
  const [refreshIndex, setRefreshIndex] = useState(0);

  useEffect(() => {
    getContentTypes(sdk).then((types) => {
      setValue(types);
    });
  }, [sdk, refreshIndex]);

  return {
    data: value,
    refresh: () => setTimeout(() => setRefreshIndex(refreshIndex + 1)),
  };
};
