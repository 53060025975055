import { AbTestSelector, ContextConfig } from '@uniformdev/context-ui';
import { Test } from '@uniformdev/context/api';
import { FieldConnector } from '@contentful/field-editor-shared';
import { FieldProps } from '../../types/FieldProps';

const AbTestCustomField = function ({ sdk, config }: FieldProps & { config: ContextConfig }) {
  return (
    <FieldConnector<Test> field={sdk.field} isInitiallyDisabled={false}>
      {(props) => {
        const { value, setValue } = props
        return (
          <AbTestSelector
            contextConfig={config}
            setValue={setValue}
            value={value ?? undefined}
          />
        );
      }}
    </FieldConnector>
  );
};

export default AbTestCustomField;
