import React from 'react';
import { createRoot } from 'react-dom/client';

import {
  AppExtensionSDK,
  FieldExtensionSDK,
  DialogExtensionSDK,
  BaseExtensionSDK,
  init,
  locations,
} from '@contentful/app-sdk';
import { GlobalStyles } from '@contentful/f36-components';
import './index.css';

import { Theme, IconsProvider } from '@uniformdev/context-ui';

import ConfigScreen from './components/ConfigScreen/ConfigScreen';
import FieldEditor from './components/FieldEditor/FieldEditor';
import Dialog from './components/Dialog';

// Sentry
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { AnalyticsProvider, Analytics, hubspot } from '@uniformdev/telemetry';

// Initialize analytics
const analyticsInstance = Analytics({
  app: 'uniform',
  plugins: [
    hubspot.hubSpotPlugin({
      portalId: process.env.REACT_APP_HUBSPOT_PID ?? '',
      useEvents: false,
    }),
  ],
});

// Initialize Sentry
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN ?? '',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  release: process.env.REACT_APP_SENTRY_RELEASE,
});

init((sdk: BaseExtensionSDK) => {
  const root = document.getElementById('root');
  const reactRoot = createRoot(root!);

  analyticsInstance.identify(sdk.user.email, {
    email: sdk.user.email,
  });

  const ComponentLocationSettings = [
    {
      location: locations.LOCATION_APP_CONFIG,
      component: <ConfigScreen sdk={sdk as unknown as AppExtensionSDK} />,
    },
    {
      location: locations.LOCATION_ENTRY_FIELD,
      component: (
        <>
          {/* https://github.com/uniformdev/optimize/issues/737
          scoped with a style tag because otherwise it breaks
          scrollbars on the config page */}
          <style
            dangerouslySetInnerHTML={{
              __html: `
              body {
                overflow: hidden;
              }`,
            }}
          />
          <FieldEditor sdk={sdk as FieldExtensionSDK} />
        </>
      ),
    },
    {
      location: locations.LOCATION_DIALOG,
      component: <Dialog sdk={sdk as unknown as DialogExtensionSDK} />,
    },
  ];

  ComponentLocationSettings.forEach((componentLocationSetting) => {
    if (sdk.location.is(componentLocationSetting.location)) {
      reactRoot.render(
        <Sentry.Profiler>
          <AnalyticsProvider instance={analyticsInstance}>
            {/* Do not include Context UI styles to Config page, to avoid extra styles and reset.css */}
            {componentLocationSetting.location !== locations.LOCATION_APP_CONFIG && <Theme />}
            <GlobalStyles />
            <IconsProvider>{componentLocationSetting.component}</IconsProvider>
          </AnalyticsProvider>
        </Sentry.Profiler>
      );
    }
  });
});
