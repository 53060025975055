import React from 'react';
import { MultipleEntryReferenceEditor } from '@contentful/field-editor-reference';
import { FieldProps } from '../../types/FieldProps';
import { PersonalizationListCard } from './atoms/PersonalizationListCard';
import {
  OptimizeConfig,
  OptimizeDataContext as BaseOptimizeDataContext,
  FieldEditorLoading,
  FieldEditorError,
  FieldEditorNoIntents,
} from '@uniformdev/optimize-cms-react';

export type PersonalizeListEditorProps = FieldProps & { optimizeConfig: OptimizeConfig };

function withChildren<T extends React.FC<any>>(
  component: T
): React.FC<React.PropsWithChildren<React.ComponentProps<T>>> {
  return component;
}

// react 18 workaround
const OptimizeDataContext = withChildren(BaseOptimizeDataContext);

export const PersonalizationListEditor: React.FC<PersonalizeListEditorProps> = ({ sdk, optimizeConfig }) => {
  return (
    <OptimizeDataContext
      optimizeConfig={optimizeConfig}
      loadingComponent={FieldEditorLoading}
      errorComponent={FieldEditorError}
      noIntentsComponent={FieldEditorNoIntents}
    >
      {/* HACK for #42 until Contentful fixes their editor - see https://github.com/contentful/field-editors/issues/415#issuecomment-699860444 */}
      <style>
        {`
.DropdownContainer__DropdownContainer___3WlJM[data-testid="add-entry-menu-container"] {
  position: static !important;
  display: inline-block !important;
  margin-top: 5px !important;
  transform: none !important;
}
      `}
      </style>
      <MultipleEntryReferenceEditor
        renderCustomCard={PersonalizationListCard}
        sdk={sdk as any}
        isInitiallyDisabled={false}
        viewType="link"
        parameters={{
          instance: {
            showCreateEntityAction: true,
            showLinkEntityAction: true,
          },
        }}
        hasCardEditActions={true}
      />
    </OptimizeDataContext>
  );
};
