import React from 'react';
import { ExternalLinkIcon } from '@contentful/f36-icons';
import { Button, Paragraph } from '@contentful/f36-components';
import { FieldProps } from '../../types/FieldProps';
import { IntentTagEditor } from './IntentTagEditor';
import { PersonalizationListEditor } from './PersonalizationListEditor';
import { ContextPersonalizationListEditor } from './ContextPersonalizationListEditor';
import { ContextAbTestListEditor } from './ContextAbTestListEditor';

import { ContextConfig, useValidateContextConfig } from '@uniformdev/context-ui';
import EnrichmentTagCustomField from './EnrichmentTag';
import PersonalizationCriteriaCustomField from './PersonalizationCriteria';

import {
  enrichmentTagFieldId,
  personalizationCriteriaFieldId,
  intentTagFieldId,
  abTestFieldId,
  personalizedListFieldId,
  abTestListFieldId,
} from '../../api/fieldIdProvider';
import AbTestCustomField from './AbTest';

const FieldEditor: React.FunctionComponent<FieldProps> = (props) => {
  const { sdk } = props;
  sdk.window.startAutoResizer();

  const config: ContextConfig = {
    apiKey: (sdk.parameters.installation as any).apiKey,
    projectId: (sdk.parameters.installation as any).projectId,
    apiHost: (sdk.parameters.installation as any).apiHost,
  };

  const {
    validating: validatingConfig,
    error: validationError,
    result: manifest,
  } = useValidateContextConfig(config);

  if (validatingConfig || manifest === null) {
    return <Paragraph>Validating Uniform app configuration...</Paragraph>;
  }

  if (validationError) {
    const configUrl = `https://app.contentful.com/spaces/${sdk.ids.space}/environments/${sdk.ids.environment}/apps/${sdk.ids.app}`;
    return (
      <div className="form36">
        <Paragraph>
          Connection to Uniform is not configured.{' '}
          <Button startIcon={<ExternalLinkIcon />} onClick={() => window.open(configUrl)}>
            Configure Uniform app
          </Button>
        </Paragraph>
      </div>
    );
  }

  const fieldType = sdk.field.type;

  if (fieldType === 'Object') {
    return (
      <>
        {sdk.field.id === intentTagFieldId && (
          <IntentTagEditor
            {...props}
            optimizeConfig={{
              optimizeApiHost: config.apiHost,
              optimizeApiKey: config.apiKey,
              optimizeProjectId: config.projectId,
            }}
          />
        )}
        {sdk.field.id === enrichmentTagFieldId && <EnrichmentTagCustomField config={config} sdk={sdk} />}
        {sdk.field.id === personalizationCriteriaFieldId && (
          <PersonalizationCriteriaCustomField config={config} sdk={sdk} />
        )}
        {sdk.field.id === abTestFieldId && <AbTestCustomField config={config} sdk={sdk} />}
      </>
    );
  }

  if (fieldType === 'Array' && sdk.field.items?.type === 'Link') {
    if (sdk.field.id === personalizedListFieldId) {
      if (manifest?.project.ui_version === 1) {
        return (
          <PersonalizationListEditor
            {...props}
            optimizeConfig={{
              optimizeApiHost: config.apiHost,
              optimizeApiKey: config.apiKey,
              optimizeProjectId: config.projectId,
            }}
          />
        );
      }
      return <ContextPersonalizationListEditor {...props} config={config} />;
    }
    if (sdk.field.id === abTestListFieldId) {
      return <ContextAbTestListEditor {...props} config={config} />
    }
  }

  return <Paragraph>The Uniform editor does not support {fieldType} fields.</Paragraph>;
};

export default FieldEditor;
