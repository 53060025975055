import { Note } from '@contentful/f36-components';
import React from 'react';

export const SaveWarning: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <Note variant="warning" style={{ marginBottom: '1.5rem' }}>
      {children ??
        'Please make sure to click the "Save" button in the top right corner to apply these changes.'}
    </Note>
  );
};
