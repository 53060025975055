import { ContentType } from '@contentful/app-sdk';
import { abTestListFieldId, personalizedListFieldId } from '../../../api/fieldIdProvider';

interface UseContentTypesResult {
  all: ContentType[];
  selected: ContentType[];
}

export const useFilteredContentTypes = (types: ContentType[], fieldId: string): UseContentTypesResult => {
  const selected = [] as ContentType[];
  const all = [] as ContentType[];
  types.forEach((ct) => {
    const contentType = ct as ContentType;

    if (contentType.fields.some((f) => f.id === fieldId)) {
      selected.push(contentType);
    } else if (contentType.fields.every((f) => f.id !== personalizedListFieldId)) {
      all.push(contentType);
    } else if (contentType.fields.every((f) => f.id !== abTestListFieldId)) {
      all.push(contentType);
    }
  });

  return {
    all,
    selected,
  };
};
