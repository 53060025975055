import React from 'react';
import { FieldConnector } from '@contentful/field-editor-shared';
import { FieldProps } from '../../types/FieldProps';
import { IntentTags } from '@uniformdev/optimize-common';
import { IntentTagEditor as BaseIntentTagEditor, OptimizeConfig } from '@uniformdev/optimize-cms-react';
import { useAnalytics } from '@uniformdev/telemetry';

export type IntentTagEditorProps = FieldProps & { optimizeConfig: OptimizeConfig };

export const IntentTagEditor: React.FC<IntentTagEditorProps> = ({ sdk, optimizeConfig }) => {
  const analytics = useAnalytics();

  return (
    <FieldConnector<IntentTags> field={sdk.field} isInitiallyDisabled={false}>
      {({ value, setValue }) => {
        return (
          <BaseIntentTagEditor
            value={value}
            setValue={setValue}
            optimizeConfig={optimizeConfig}
            telemetryTracker={analytics}
          />
        );
      }}
    </FieldConnector>
  );
};
