import React, { FunctionComponent } from 'react';
import { Menu, EntryCard } from '@contentful/f36-components';
import { CustomEntityCardProps } from '@contentful/field-editor-reference';
import { entityHelpers, Entry } from '@contentful/field-editor-shared';
import { UniformLogoPlain } from '@uniformdev/optimize-cms-react';
import { intentTagFieldId } from '../../../api/fieldIdProvider';
import {
  useOptimizeEnrichmentsDataContext,
  useOptimizeDataContext,
  IntentOrEnrichment,
  processOptimizeIntents,
} from '@uniformdev/optimize-cms-react';
import { IntentTags, getPersonalizationIntents } from '@uniformdev/optimize-common';

const { getEntryTitle, getEntryStatus } = entityHelpers;

function getIntentTags(entry: Entry): IntentTags {
  const entryTags = entry.fields[intentTagFieldId];

  if (!entryTags) {
    // no intents tagged
    return {
      intents: {},
    };
  }

  // despite not being localizable the intents have a language key, i.e. 'en-US'
  // after this intents is an array of strings of intent category names
  return entryTags[Object.keys(entryTags)[0]];
}

function resolveTag(intentId: string, optimizeData: IntentOrEnrichment[]): IntentOrEnrichment {
  const intent = optimizeData.find((intent) => intent.id === intentId);

  if (!intent) {
    return {
      name: `${intentId} (unknown)`,
      id: intentId,
      isEnrichment: false,
      isLatestPublished: false,
      isPublished: false,
      signals: [],
    };
  }
  return intent;
}

export type NotADragHandleProps = {
  isDragging?: boolean;
  entity: Entry;
  drag: React.ReactElement;
  sortableDragHandle?: CustomEntityCardProps['renderDragHandle'];
};

const NotADragHandle: FunctionComponent<NotADragHandleProps> = (props) => {
  const pnIntentTags = getPersonalizationIntents(getIntentTags(props.entity as Entry));
  const optimizeData = useOptimizeDataContext();
  const enrichmentData = useOptimizeEnrichmentsDataContext();

  const intentTags = processOptimizeIntents(optimizeData.site.intents, enrichmentData.enrichments);

  const pnIntentTagIds = Object.keys(pnIntentTags);

  const tags = pnIntentTagIds
    .filter((tagId) => pnIntentTags[tagId])
    .map((tagId) => ({ data: resolveTag(tagId, intentTags), value: pnIntentTags[tagId] }));

  const sortedTags = tags.sort((a, b) => {
    const typeMatch = Number(a.data.isEnrichment) - Number(b.data.isEnrichment);

    if (typeMatch !== 0) {
      return typeMatch;
    }

    return a.data.name.localeCompare(b.data.name);
  });

  return (
    <>
      {props.sortableDragHandle && props.sortableDragHandle({ drag: props.drag })}
      <div className="uo-plist-card-contents">
        <div className="uo-plist-card-contents__icon-wrapper">
          <UniformLogoPlain className="uo-plist-card-contents__icon" />
          <span>Tags</span>
        </div>
        <div className="uo-plist-card-contents__tag-list">
          {tags.length === 0 && (
            <span className="uo-plist-card-contents__tag-list-item uo-plist-card-contents__tag-list-item-default">
              Default Variation
            </span>
          )}
          {sortedTags.map((tag) => (
            <span
              key={tag.data.name}
              className={`uo-plist-card-contents__tag-list-item uo-plist-card-contents__tag-list-item--str${
                tag.value.str
              }${tag.value.override ? ' uo-plist-card-contents__tag-list-item--override' : ''}`}
            >
              {tag.data.name}
              {tag.value.threshold ? `≥${tag.value.threshold}` : null}
            </span>
          ))}
        </div>
      </div>
    </>
  );
};

export const PersonalizationListCard: (props: CustomEntityCardProps) => React.ReactElement = (props) => {
  const { contentType, entity, localeCode, defaultLocaleCode } = props;

  const entry = entity as Entry;

  const title = getEntryTitle({
    entry,
    contentType,
    localeCode,
    defaultLocaleCode,
    defaultTitle: 'Untitled',
  });

  const status = getEntryStatus(props.entity?.sys);

  // ref: https://github.com/contentful/field-editors/blob/844c9c2482a90d211d485e5134e6f12b629a6b6c/packages/reference/src/entries/WrappedEntryCard/WrappedEntryCard.tsx
  return (
    <EntryCard
      href={props.entityUrl}
      as="a"
      key={entity.sys.id}
      contentType={contentType?.name}
      title={title}
      size="auto"
      status={status}
      className="draggable-custom-entry-card"
      dragHandleRender={({ isDragging, drag }) => (
        <NotADragHandle
          sortableDragHandle={props.renderDragHandle}
          drag={drag}
          isDragging={isDragging}
          entity={entity}
        />
      )}
      // dragHandleRender={props.renderDragHandle}
      withDragHandle
      actions={[
        <Menu.Item
          key="edit"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            props.onEdit && props.onEdit();
          }}
        >
          Edit
        </Menu.Item>,
        <Menu.Item
          key="remove"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            props.onRemove && props.onRemove();
          }}
        >
          Remove
        </Menu.Item>,
      ]}
      onClick={(e) => {
        e.preventDefault();
        props.onEdit && props.onEdit();
      }}
    />
  );
};
