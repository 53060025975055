import React from 'react';
import { useOptimizeData, OptimizeConfig } from '@uniformdev/optimize-cms-react';
import { PlusIcon, EditIcon } from '@contentful/f36-icons';
import { Paragraph, Card, SectionHeading, Button } from '@contentful/f36-components';
import { LoadingIndicator } from '@uniformdev/context-ui';

interface ConnectionInfoProps {
  config: OptimizeConfig;
}

export const OptimizeInfo: React.FunctionComponent<ConnectionInfoProps> = ({ config }) => {
  const { loading, result } = useOptimizeData(config);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (result) {
    const {
      site,
      site: { intents, enrichments },
    } = result;

    const hasIntents = intents.length > 0;

    return (
      <Card>
        <SectionHeading
          className={
            hasIntents ? `uo-config__connected` : `uo-config__connected uo-config__connected--noIntents`
          }
        >
          Connected to Uniform
        </SectionHeading>

        <Paragraph>
          <strong>Project:</strong> {site.name}
        </Paragraph>
        {hasIntents && (
          <>
            <Paragraph>
              <strong>Intents:</strong> {intents.length}
            </Paragraph>
            {enrichments?.length ? (
              <Paragraph>
                <strong>Enrichments:</strong> {enrichments.length}
              </Paragraph>
            ) : null}
            <div>
              <Button
                startIcon={<EditIcon />}
                onClick={() => window.open(`${config.optimizeApiHost}/projects/${site.id}`)}
              >
                Open in Uniform
              </Button>
            </div>
          </>
        )}
        {!hasIntents && (
          <>
            <Paragraph>
              You are connected, but there are no <em>intents</em> defined in the connected Uniform site.
              Until intents are created, you will not be able to configure personalization within Contentful
              because intents create audiences to show personalized content to.
            </Paragraph>
            <div>
              <Button
                startIcon={<PlusIcon />}
                onClick={() => window.open(`${config.optimizeApiHost}/projects/${site.id}`)}
              >
                Create Intents in Uniform
              </Button>
            </div>
          </>
        )}
      </Card>
    );
  }

  return null;
};
