// ../../scripts/emotion-jsx-shim.js
import { jsx } from "@emotion/react";
import * as React from "react";

// src/components/CriteriaOperatorMenu/CriteriaOperatorMenu.tsx
import { InputComboBox } from "@uniformdev/design-system";

// src/components/CriteriaOperatorMenu/OperatorBubble.tsx
import { css } from "@emotion/react";
import { jsx as jsx2 } from "@emotion/react/jsx-runtime";
function OperatorBubble({ op }) {
  return /* @__PURE__ */ jsx2(
    "div",
    {
      className: "operation-bubble",
      css: css`
        align-items: center;
        background: var(--gray-700);
        border-radius: var(--rounded-full);
        color: var(--white);
        display: flex;
        width: 30px;
        height: 30px;
        justify-content: center;
        font-size: var(--fs-base);
      `,
      children: op
    }
  );
}

// src/components/CriteriaOperatorMenu/CriteriaOperatorOption.tsx
import { jsx as jsx3, jsxs } from "@emotion/react/jsx-runtime";
var CriteriaOperatorOption = (props) => {
  var _a, _b;
  const { data, getStyles, isDisabled, innerRef, innerProps } = props;
  const [name, description] = (_b = (_a = data.label) == null ? void 0 : _a.split(":")) != null ? _b : [];
  return /* @__PURE__ */ jsxs(
    "div",
    {
      css: {
        ...getStyles("option", props),
        display: "flex",
        alignItems: "center",
        gap: "var(--spacing-sm)"
      },
      ref: innerRef,
      "aria-disabled": isDisabled,
      ...innerProps,
      children: [
        description ? /* @__PURE__ */ jsx3(OperatorBubble, { op: name }) : /* @__PURE__ */ jsx3(
          "div",
          {
            css: {
              width: "20px",
              height: "20px"
            }
          }
        ),
        /* @__PURE__ */ jsx3("div", { children: description != null ? description : name })
      ]
    }
  );
};

// src/components/CriteriaOperatorMenu/CriteriaOperatorSingleValue.tsx
import { jsx as jsx4 } from "@emotion/react/jsx-runtime";
var CriteriaOperatorSingleValue = (props) => {
  const { data, getStyles } = props;
  return /* @__PURE__ */ jsx4(
    "div",
    {
      css: {
        ...getStyles("singleValue", props),
        width: "max-content"
      },
      children: data.label.length === 1 ? /* @__PURE__ */ jsx4(OperatorBubble, { op: data.label }) : data.label
    }
  );
};

// src/components/CriteriaOperatorMenu/CriteriaOperatorMenu.tsx
import { jsx as jsx5 } from "@emotion/react/jsx-runtime";
var contextCriteriaMenuOperators = [
  {
    name: "=",
    description: "equals",
    value: "="
  },
  {
    name: "\u2260",
    description: "not equal",
    value: "!="
  },
  {
    name: ">",
    description: "is greater than",
    value: ">"
  },
  {
    name: "\u2265",
    description: "is greater than or equal to",
    value: ">="
  },
  {
    name: "<",
    description: "is less than",
    value: "<"
  },
  {
    name: "\u2264",
    description: "is less than or equal to",
    value: "<="
  },
  {
    name: "has the strongest score",
    value: "+"
  },
  {
    name: "has the weakest score",
    value: "-"
  }
];
function CriteriaOperatorMenu({ onChange, value, ...props }) {
  var _a, _b;
  return /* @__PURE__ */ jsx5(
    InputComboBox,
    {
      ...props,
      value: {
        label: (_b = (_a = contextCriteriaMenuOperators.find((e) => e.value === value)) == null ? void 0 : _a.name) != null ? _b : value,
        value
      },
      options: contextCriteriaMenuOperators.map((option) => ({
        label: option.description ? `${option.name}:${option.description}` : option.name,
        value: option.value
      })),
      styles: {
        ...props.styles,
        valueContainer: (provided, state) => {
          var _a2, _b2;
          return {
            ...provided,
            padding: "var(--spacing-sm)",
            ...(_b2 = (_a2 = props.styles) == null ? void 0 : _a2.valueContainer) == null ? void 0 : _b2.call(_a2, provided, state)
          };
        },
        menu: (provided, state) => {
          var _a2, _b2;
          return {
            ...provided,
            width: "max-content",
            ...(_b2 = (_a2 = props.styles) == null ? void 0 : _a2.menu) == null ? void 0 : _b2.call(_a2, provided, state)
          };
        },
        control: (provided, state) => {
          var _a2, _b2;
          return {
            ...provided,
            border: 0,
            ...(_b2 = (_a2 = props.styles) == null ? void 0 : _a2.control) == null ? void 0 : _b2.call(_a2, provided, state)
          };
        },
        indicatorSeparator: (provided, state) => {
          var _a2, _b2;
          return {
            ...provided,
            display: "none",
            ...(_b2 = (_a2 = props.styles) == null ? void 0 : _a2.indicatorSeparator) == null ? void 0 : _b2.call(_a2, provided, state)
          };
        }
      },
      onChange: (e) => {
        if (e) {
          onChange(e.value);
        }
      },
      components: {
        SingleValue: CriteriaOperatorSingleValue,
        Option: CriteriaOperatorOption,
        ...props.components
      }
    }
  );
}

// src/components/DataContext/DataContext.tsx
import { LoadingIndicator } from "@uniformdev/design-system";
import { createContext, useContext } from "react";

// src/hooks/useContextData.ts
import { ApiClientError, CachedManifestClient } from "@uniformdev/context/api";
import { useEffect, useState } from "react";
function useContextData({ apiHost, apiKey, projectId }) {
  const [state, setState] = useState({
    loading: false,
    notConfigured: false,
    error: null,
    result: null
  });
  useEffect(() => {
    if (!projectId || !apiKey || !apiHost) {
      setState({ notConfigured: true, loading: false, error: null, result: null });
      return;
    }
    const runEffect = async () => {
      setState({ notConfigured: false, loading: true, error: null, result: null });
      try {
        const client = new CachedManifestClient({
          projectId,
          apiKey,
          apiHost
        });
        const result = await client.get({ preview: true });
        setState({ notConfigured: false, loading: false, error: null, result });
      } catch (e) {
        let message;
        if (e instanceof ApiClientError) {
          if (e.statusCode === 403) {
            message = `The API key ${apiKey} did not have permissions to fetch the manifest. Ensure Context > Read Drafts permissions are granted.`;
          }
          message = e.message;
        } else {
          message = e.toString();
        }
        setState({ notConfigured: false, loading: false, error: message, result: null });
        return;
      }
    };
    runEffect();
  }, [apiHost, apiKey, projectId]);
  return {
    result: state.result,
    error: state.error,
    loading: state.loading,
    notConfigured: state.notConfigured
  };
}

// src/hooks/useDimensions.ts
import { ApiClientError as ApiClientError2, CachedDimensionClient, computeDimensionDisplayName } from "@uniformdev/context/api";
import { useEffect as useEffect2, useState as useState2 } from "react";

// src/utils/objectify.ts
function objectify(array2, keySelector, valueSelector) {
  return array2.reduce((prev, current) => {
    const key = keySelector(current);
    if (typeof key === "undefined" || key === null) {
      throw new Error(`Objectify key selector returned undefined or null.`);
    }
    prev[keySelector(current)] = valueSelector ? valueSelector(current) : current;
    return prev;
  }, {});
}

// src/hooks/useDimensions.ts
function useDimensions({ apiHost, apiKey, projectId }) {
  const [state, setState] = useState2({
    loading: false,
    notConfigured: false,
    error: null,
    result: null
  });
  useEffect2(() => {
    if (!projectId || !apiKey || !apiHost) {
      setState({ notConfigured: true, loading: false, error: null, result: null });
      return;
    }
    const runEffect = async () => {
      setState({ notConfigured: false, loading: true, error: null, result: null });
      try {
        const client = new CachedDimensionClient({
          projectId,
          apiKey,
          apiHost
        });
        const dimensions = (await client.get()).dimensions.map((dim) => ({
          ...dim,
          displayName: computeDimensionDisplayName(dim)
        }));
        const result = {
          dimensions,
          dimIndex: objectify(
            dimensions,
            (k) => k.dim,
            (v) => v
          )
        };
        setState({ notConfigured: false, loading: false, error: null, result });
      } catch (e) {
        let message;
        if (e instanceof ApiClientError2) {
          message = e.message;
        } else {
          message = e.toString();
        }
        setState({ notConfigured: false, loading: false, error: message, result: null });
        return;
      }
    };
    runEffect();
  }, [apiHost, apiKey, projectId]);
  return {
    result: state.result,
    error: state.error,
    loading: state.loading,
    notConfigured: state.notConfigured
  };
}

// src/components/DataContext/DataContext.tsx
import { Fragment, jsx as jsx6 } from "@emotion/react/jsx-runtime";
var ContextDataContext = createContext(null);
var ContextData = ({
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  contextConfig,
  children
}) => {
  const contextData = useContextData(contextConfig);
  const dimensionsData = useDimensions(contextConfig);
  if (contextData.error || contextData.notConfigured) {
    if (ErrorComponent)
      return /* @__PURE__ */ jsx6(ErrorComponent, { contextConfig, result: contextData });
    else
      return /* @__PURE__ */ jsx6(Fragment, { children: "ErrorComponent is not configured" });
  }
  if (dimensionsData.error || dimensionsData.notConfigured) {
    if (ErrorComponent)
      return /* @__PURE__ */ jsx6(ErrorComponent, { contextConfig, result: dimensionsData });
    else
      return /* @__PURE__ */ jsx6(Fragment, { children: "ErrorComponent is not configured" });
  }
  if (contextData.loading || dimensionsData.loading) {
    if (LoadingComponent)
      return /* @__PURE__ */ jsx6(LoadingComponent, {});
    else
      return /* @__PURE__ */ jsx6(LoadingIndicator, {});
  }
  return /* @__PURE__ */ jsx6(
    ContextDataContext.Provider,
    {
      value: { manifest: contextData.result, dimensions: dimensionsData.result, contextConfig },
      children
    }
  );
};
function useContextConfig() {
  const context = useContext(ContextDataContext);
  if (!(context == null ? void 0 : context.contextConfig)) {
    throw new Error("Not within DataContext! Configuration data is not exist.");
  }
  return context.contextConfig;
}
function useManifest() {
  const context = useContext(ContextDataContext);
  if (!(context == null ? void 0 : context.manifest)) {
    throw new Error("Not within DataContext! Manifest data is not exist.");
  }
  return context.manifest;
}
function useDimensionsDataContext() {
  const context = useContext(ContextDataContext);
  if (!(context == null ? void 0 : context.dimensions)) {
    throw new Error("Not within DataContext! Dimensions data is not exist.");
  }
  return context.dimensions;
}

// src/components/DimensionMenu/CriteriaMatchMenu.tsx
import { InputComboBox as InputComboBox3 } from "@uniformdev/design-system";
import { useState as useState3 } from "react";

// src/components/DimensionMenu/DimensionGroupHeading.tsx
import { Icon } from "@uniformdev/design-system";

// src/components/DimensionMenu/utils.ts
function dimensionToMenuOption(dimension) {
  return {
    label: dimension.displayName,
    value: dimension.dim,
    isDisabled: false
  };
}
function dimensionIcon(displayName) {
  if (!displayName) {
    return "unavailable";
  }
  const [type] = displayName.split(":");
  switch (type.toLowerCase()) {
    case "signal":
      return "data";
    case "intent":
      return "assign";
    case "audience":
      return "boy";
    default:
      return "user-list";
  }
}
function groupDimensions(dimensions) {
  const result = [];
  let lastType = "";
  for (const dim of dimensions) {
    const [type] = dim.displayName.split(":");
    if (lastType !== type) {
      result.push({ label: type, options: [] });
      lastType = type;
    }
    result[result.length - 1].options.push(dimensionToMenuOption(dim));
  }
  return result;
}

// src/components/DimensionMenu/DimensionGroupHeading.tsx
import { jsx as jsx7, jsxs as jsxs2 } from "@emotion/react/jsx-runtime";
var DimensionGroupHeading = (props) => {
  var _a;
  const { data, getStyles, className } = props;
  return /* @__PURE__ */ jsx7(
    "div",
    {
      css: {
        ...getStyles("groupHeading", props),
        textTransform: "none",
        fontSize: "var(--font-size-sm)"
      },
      className,
      children: /* @__PURE__ */ jsxs2(
        "small",
        {
          css: { color: "var(--gray-500)", display: "flex", alignItems: "center", gap: "var(--spacing-xs)" },
          children: [
            /* @__PURE__ */ jsx7(Icon, { icon: dimensionIcon((_a = data.label) != null ? _a : ""), iconColor: "currentColor", size: 16 }),
            /* @__PURE__ */ jsx7("span", { children: data.label })
          ]
        }
      )
    }
  );
};

// src/components/DimensionMenu/DimensionMenuErrorMessage.tsx
import { css as css2 } from "@emotion/react";
import { jsx as jsx8 } from "@emotion/react/jsx-runtime";
function DimensionMenuErrorMessage({ message }) {
  if (!message)
    return null;
  return /* @__PURE__ */ jsx8(
    "div",
    {
      css: css2`
        color: var(--brand-primary-2);
        font-size: var(--fs-xs);
        position: absolute;
        bottom: calc((var(--spacing-base) * -1) + -2px);
      `,
      children: message
    }
  );
}

// src/components/DimensionMenu/DimensionOption.tsx
import { jsx as jsx9 } from "@emotion/react/jsx-runtime";
var DimensionOption = (props) => {
  var _a, _b;
  const { data, getStyles, cx, isDisabled, isFocused, isSelected, className, innerRef, innerProps } = props;
  const [, value] = (_b = (_a = data.label) == null ? void 0 : _a.split(":")) != null ? _b : [];
  return /* @__PURE__ */ jsx9(
    "div",
    {
      css: getStyles("option", props),
      className: cx(
        {
          option: true,
          "option--is-disabled": isDisabled,
          "option--is-focused": isFocused,
          "option--is-selected": isSelected
        },
        className
      ),
      ref: innerRef,
      "aria-disabled": isDisabled,
      ...innerProps,
      children: /* @__PURE__ */ jsx9("div", { css: { color: "var(--gray-700)" }, children: value != null ? value : data.label })
    }
  );
};

// src/components/DimensionMenu/DimensionValue.tsx
import { Icon as Icon2 } from "@uniformdev/design-system";
import { jsx as jsx10, jsxs as jsxs3 } from "@emotion/react/jsx-runtime";
function DimensionValue({ displayName }) {
  const [type, name] = displayName.split(":");
  return /* @__PURE__ */ jsxs3(
    "div",
    {
      css: {
        whiteSpace: "normal",
        overflow: "hidden"
      },
      children: [
        name ? /* @__PURE__ */ jsxs3(
          "small",
          {
            css: { color: "var(--gray-500)", display: "flex", alignItems: "center", gap: "var(--spacing-xs)" },
            children: [
              type ? /* @__PURE__ */ jsx10(Icon2, { icon: dimensionIcon(type), iconColor: "currentColor", size: 16 }) : null,
              /* @__PURE__ */ jsx10("span", { "data-testid": "dimension-name", children: type })
            ]
          }
        ) : null,
        /* @__PURE__ */ jsx10("div", { css: { color: "var(--gray-700)" }, "data-testid": "dimension-value", children: name != null ? name : type })
      ]
    }
  );
}

// src/components/DimensionMenu/DimensionSingleValue.tsx
import { jsx as jsx11 } from "@emotion/react/jsx-runtime";
var DimensionSingleValue = (props) => {
  const { data, getStyles } = props;
  return /* @__PURE__ */ jsx11("div", { css: getStyles("singleValue", props), children: /* @__PURE__ */ jsx11(DimensionValue, { displayName: data.label }) });
};

// src/components/DimensionMenu/CriteriaMatchMenu.tsx
import { Fragment as Fragment2, jsx as jsx12, jsxs as jsxs4 } from "@emotion/react/jsx-runtime";
function CriteriaMatchMenu({
  onChange,
  criteriaMatch,
  dimensions,
  errorMessage,
  ...props
}) {
  var _a, _b;
  const [inputValue, setInputValue] = useState3(
    typeof criteriaMatch.r !== "undefined" && isInt(criteriaMatch.r) !== null ? criteriaMatch.r.toString(10) : ""
  );
  const rDim = criteriaMatch.rDim;
  const targetDim = criteriaMatch.rDim ? dimensions.dimIndex[criteriaMatch.rDim] : void 0;
  return /* @__PURE__ */ jsxs4(Fragment2, { children: [
    /* @__PURE__ */ jsx12(
      InputComboBox3,
      {
        ...props,
        inputValue,
        menuShouldScrollIntoView: true,
        value: {
          label: (_a = targetDim == null ? void 0 : targetDim.displayName) != null ? _a : rDim && !targetDim ? `${criteriaMatch.rDim} (unknown)` : "",
          value: (_b = criteriaMatch.rDim) != null ? _b : criteriaMatch.r ? criteriaMatch.r.toString(10) : "",
          isDisabled: false
        },
        options: [
          {
            label: "Enter a numeric score to match, or choose another dimension to match its score",
            value: "",
            isDisabled: true
          },
          ...groupDimensions(dimensions.dimensions)
        ],
        styles: {
          ...props.styles,
          valueContainer: (provided, state) => {
            var _a2, _b2;
            return {
              ...provided,
              padding: "var(--spacing-sm)",
              ...(_b2 = (_a2 = props.styles) == null ? void 0 : _a2.valueContainer) == null ? void 0 : _b2.call(_a2, provided, state)
            };
          },
          option: (provided, state) => {
            var _a2, _b2;
            return {
              fontSize: state.isDisabled ? "0.8rem" : void 0,
              ...(_b2 = (_a2 = props.styles) == null ? void 0 : _a2.option) == null ? void 0 : _b2.call(_a2, provided, state)
            };
          }
        },
        onChange: (e) => {
          var _a2;
          if (e) {
            onChange({ ...criteriaMatch, rDim: (_a2 = e.value) == null ? void 0 : _a2.toString(), r: void 0 });
            setInputValue("");
          }
        },
        onInputChange: (newValue, meta) => {
          const isValueInt = isInt(newValue);
          if (meta.action === "input-change" || meta.action === "set-value") {
            setInputValue(newValue);
            if (isValueInt || newValue === "") {
              onChange({ ...criteriaMatch, r: newValue || void 0, rDim: void 0 });
            }
          } else if (!isValueInt && !isInt(meta.prevInputValue)) {
            if (!newValue && criteriaMatch.r) {
              setInputValue(criteriaMatch.r.toString());
            } else {
              setInputValue(newValue);
            }
          }
        },
        components: {
          ...props.components,
          Option: DimensionOption,
          SingleValue: DimensionSingleValue,
          GroupHeading: DimensionGroupHeading
        },
        noOptionsMessage: ({ inputValue: inputValue2 }) => {
          if (isInt(inputValue2)) {
            return /* @__PURE__ */ jsxs4(Fragment2, { children: [
              /* @__PURE__ */ jsxs4("div", { children: [
                "Score: ",
                inputValue2
              ] }),
              /* @__PURE__ */ jsx12("small", { children: "If you want to match on another dimension\u2019s score instead, clear the score value to search for a dimension." })
            ] });
          }
          return /* @__PURE__ */ jsxs4(Fragment2, { children: [
            /* @__PURE__ */ jsxs4("div", { children: [
              "No dimensions match your search \u201C",
              inputValue2,
              "\u201D"
            ] }),
            /* @__PURE__ */ jsx12("small", { children: "If you want to match a literal score, enter a numeric value." })
          ] });
        }
      }
    ),
    /* @__PURE__ */ jsx12(DimensionMenuErrorMessage, { message: errorMessage })
  ] });
}
function isInt(value) {
  return /^\d+$/.test(value.toString(10));
}

// src/components/DimensionMenu/DimensionMenu.tsx
import { InputComboBox as InputComboBox4 } from "@uniformdev/design-system";
import { Fragment as Fragment3, jsx as jsx13, jsxs as jsxs5 } from "@emotion/react/jsx-runtime";
function DimensionMenu({ onChange, value, dimensions, errorMessage, ...props }) {
  return /* @__PURE__ */ jsxs5(Fragment3, { children: [
    /* @__PURE__ */ jsx13(
      InputComboBox4,
      {
        ...props,
        value: value ? dimensionToMenuOption(value) : void 0,
        options: groupDimensions(dimensions),
        styles: {
          ...props.styles,
          valueContainer: (provided, state) => {
            var _a, _b;
            return {
              ...provided,
              padding: "var(--spacing-sm)",
              ...(_b = (_a = props.styles) == null ? void 0 : _a.valueContainer) == null ? void 0 : _b.call(_a, provided, state)
            };
          }
        },
        onChange: (e) => {
          if (e) {
            onChange(dimensions.find((d) => d.dim === e.value));
          }
        },
        components: {
          Option: DimensionOption,
          SingleValue: DimensionSingleValue,
          GroupHeading: DimensionGroupHeading,
          ...props.components
        }
      }
    ),
    /* @__PURE__ */ jsx13(DimensionMenuErrorMessage, { message: errorMessage })
  ] });
}

// src/components/EditLink/EditLink.tsx
import { CgChevronRight } from "@react-icons/all-files/cg/CgChevronRight";
import { Icon as Icon3 } from "@uniformdev/design-system";

// src/components/EditLink/EditLink.styles.ts
import { css as css3 } from "@emotion/react";
var editLink = css3`
  display: flex;
  align-items: center;
  font-weight: var(--fw-bold);
  gap: var(--spacing-sm);
  margin-top: var(--spacing-md);
  color: var(--brand-secondary-3);
  &:hover,
  &:focus {
    text-decoration-line: underline;
  }
`;

// src/components/EditLink/EditLink.tsx
import { jsx as jsx14, jsxs as jsxs6 } from "@emotion/react/jsx-runtime";
var EditLink = ({ linkTo, name, linkText = `Edit ${name} Component` }) => {
  return /* @__PURE__ */ jsxs6(
    "a",
    {
      css: editLink,
      title: `Edit ${name} component definition`,
      rel: "noopener noreferrer",
      target: "_blank",
      href: linkTo,
      children: [
        linkText,
        /* @__PURE__ */ jsx14(Icon3, { icon: CgChevronRight, iconColor: "currentColor", size: "1.5rem" })
      ]
    }
  );
};

// src/components/EnrichmentTag/EnrichmentTag.tsx
import { css as css4 } from "@emotion/react";
import { CgCloseO } from "@react-icons/all-files/cg/CgCloseO";
import { CgMathMinus } from "@react-icons/all-files/cg/CgMathMinus";
import { CgMathPlus } from "@react-icons/all-files/cg/CgMathPlus";
import { getEnrichmentVectorKey } from "@uniformdev/context";
import {
  AddListButton,
  Button,
  Callout,
  Icon as Icon4,
  Input,
  InputSelect,
  LoadingIndicator as LoadingIndicator2
} from "@uniformdev/design-system";
import { produce } from "immer";
import { useMemo, useState as useState4 } from "react";
import { Fragment as Fragment4, jsx as jsx15, jsxs as jsxs7 } from "@emotion/react/jsx-runtime";
var addEnrichmentLink = css4`
  flex: 2;
  display: flex;
  width: 50%;
  align-items: center;
  font-weight: var(--fw-bold);
  color: var(--brand-primary-1);
  &:hover,
  &:focus {
    text-decoration-line: underline;
  }
`;
var EnrichmentTag = ({
  value,
  setValue,
  contextConfig,
  displayTitle = true
}) => {
  const { loading, result: dimensions, error } = useDimensions(contextConfig);
  const allEnrichments = useMemo(() => {
    if (dimensions)
      return dimensions.dimensions.filter((dimension) => dimension.category === "ENR");
  }, [dimensions]);
  const remainingEnrichments = useMemo(() => {
    if (!value)
      return allEnrichments;
    if (allEnrichments)
      return allEnrichments.filter(
        (enr) => !value.some((val) => getEnrichmentVectorKey(val.cat, val.key) === enr.dim)
      );
  }, [allEnrichments, value]);
  const [selectValue, setSelectValue] = useState4("");
  const [score, setScore] = useState4(50);
  const [showAddNewEnrichmentTagPanel, setShowAddNewEnrichmentTagPanel] = useState4(false);
  const selectedEnrichment = allEnrichments == null ? void 0 : allEnrichments.find((dimension) => dimension.dim === selectValue);
  const addEnrichment = () => {
    const [cat, key] = selectValue.split("_");
    update([...value != null ? value : [], { cat, key, str: score }]);
    setSelectValue("");
    setScore(50);
    setShowAddNewEnrichmentTagPanel(false);
  };
  const update = (newValue) => {
    let validData = [];
    if (dimensions) {
      validData = newValue.filter((enrichment) => {
        const dimData = dimensions.dimIndex[getEnrichmentVectorKey(enrichment.cat, enrichment.key)];
        return Boolean(dimData);
      });
    } else {
      validData = newValue;
    }
    const finalValue = validData.length === 0 ? null : validData;
    setValue(finalValue);
  };
  if (error)
    return /* @__PURE__ */ jsx15(Callout, { type: "danger", children: error });
  if (loading || dimensions === null)
    return /* @__PURE__ */ jsx15(LoadingIndicator2, {});
  return /* @__PURE__ */ jsxs7("fieldset", { className: "enrichment-tag", children: [
    displayTitle ? /* @__PURE__ */ jsx15("div", { css: { display: "flex", justifyContent: "space-between", marginBottom: "var(--spacing-base)" }, children: /* @__PURE__ */ jsx15("legend", { css: { fontSize: "var(--fs-md)", fontWeight: "var(--fw-bold)" }, children: "Enrichment Tags" }) }) : null,
    !(allEnrichments == null ? void 0 : allEnrichments.length) ? /* @__PURE__ */ jsx15(NoEnrichmentsView, { contextConfig }) : !showAddNewEnrichmentTagPanel && !value ? /* @__PURE__ */ jsx15(
      Callout,
      {
        title: "No enrichment tags assigned.",
        type: "info",
        css: { marginBlock: "var(--spacing-base)" },
        children: /* @__PURE__ */ jsxs7("p", { children: [
          "Click",
          " ",
          /* @__PURE__ */ jsx15(
            "a",
            {
              onClick: () => setShowAddNewEnrichmentTagPanel(true),
              target: "_blank",
              rel: "noopener noreferrer",
              css: { textDecorationLine: "underline" },
              children: "here"
            }
          ),
          " ",
          "to assign your first enrichment tag."
        ] })
      }
    ) : /* @__PURE__ */ jsxs7(Fragment4, { children: [
      dimensions && /* @__PURE__ */ jsx15(SelectedEnrichments, { list: value != null ? value : [], setList: update, dimIndex: dimensions.dimIndex }),
      showAddNewEnrichmentTagPanel && remainingEnrichments && remainingEnrichments.length > 0 ? /* @__PURE__ */ jsxs7(
        "div",
        {
          className: "add-enrichment-tag",
          css: {
            display: "flex",
            flexWrap: "wrap",
            gap: "var(--spacing-lg)",
            marginTop: "var(--spacing-md)",
            alignItems: "center"
          },
          children: [
            /* @__PURE__ */ jsx15("div", { css: { flexGrow: 1 }, children: /* @__PURE__ */ jsx15(
              InputSelect,
              {
                name: `enrichment-type`,
                label: "Enrichment Tag",
                showLabel: true,
                value: selectValue,
                options: [
                  { label: "Select", value: "" },
                  ...remainingEnrichments.map((enr) => ({
                    label: enr.displayName,
                    value: enr.dim
                  }))
                ],
                onChange: (e) => setSelectValue(e.currentTarget.value)
              }
            ) }),
            /* @__PURE__ */ jsx15(
              ScoreCounter,
              {
                score,
                setValue: setScore,
                cap: selectedEnrichment ? selectedEnrichment.cap : 100,
                css: { flexBasis: "9rem" }
              }
            ),
            /* @__PURE__ */ jsx15(
              Button,
              {
                buttonType: "tertiary",
                size: "xl",
                css: {
                  marginBottom: "var(--spacing-xs)",
                  height: "3.5rem",
                  margin: 0,
                  alignSelf: "flex-end"
                },
                onClick: addEnrichment,
                disabled: !selectValue,
                children: "Add"
              }
            )
          ]
        }
      ) : null,
      /* @__PURE__ */ jsxs7(
        "div",
        {
          className: "enrichment-cta",
          style: { paddingTop: "10px", display: "flex", justifyContent: "space-between" },
          children: [
            !showAddNewEnrichmentTagPanel && remainingEnrichments && remainingEnrichments.length > 0 && value ? /* @__PURE__ */ jsx15(
              AddListButton,
              {
                className: "add-more",
                buttonText: "Add More",
                onButtonClick: () => setShowAddNewEnrichmentTagPanel(true)
              }
            ) : /* @__PURE__ */ jsx15("a", { css: addEnrichmentLink, title: `none`, href: "#" }),
            /* @__PURE__ */ jsx15(
              EditLink,
              {
                name: "Enrichments",
                linkText: "Manage Enrichments",
                linkTo: `${contextConfig.apiHost}/projects/${encodeURIComponent(
                  contextConfig.projectId
                )}/personalization/enrichments`
              }
            )
          ]
        }
      )
    ] })
  ] });
};
var NoEnrichmentsView = ({ contextConfig }) => /* @__PURE__ */ jsx15(Callout, { title: "No enrichments found.", type: "caution", css: { marginBlock: "var(--spacing-base)" }, children: /* @__PURE__ */ jsxs7("p", { children: [
  "Looks like you do not have any enrichment created in your connected Uniform project. Start by creating your first enrichment",
  " ",
  /* @__PURE__ */ jsx15(
    "a",
    {
      href: `${contextConfig.apiHost}/projects/${encodeURIComponent(
        contextConfig.projectId
      )}/personalization/enrichments`,
      target: "_blank",
      rel: "noopener noreferrer",
      css: { ":hover": { textDecorationLine: "underline" } },
      children: "here"
    }
  ),
  "."
] }) });
var getCappedValue = (value, maxCap = 100, minCap = 0) => {
  return Math.max(Math.min(value, maxCap), minCap);
};
var scoreCounterMinusButtonStyles = css4`
  position: absolute;
  bottom: 0.875rem;
  left: var(--spacing-sm);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.75rem;
  width: 1.75rem;
  background-color: var(--gray-100);
  border: 1px solid var(--gray-300);
  border-radius: var(--rounded-full);
`;
var scoreCounterPlusButtonStyles = css4`
  ${scoreCounterMinusButtonStyles}
  left: auto;
  right: var(--spacing-sm);
`;
var ScoreCounter = ({
  score,
  setValue,
  cap = 100,
  ...otherProps
}) => {
  const handleCounter = (symbol) => {
    let newScore = symbol === "increment" ? score + 10 : score - 10;
    if (newScore < 0) {
      newScore = 0;
    }
    if (newScore > cap) {
      newScore = cap;
    }
    setValue(newScore);
  };
  return /* @__PURE__ */ jsxs7("div", { css: { position: "relative" }, ...otherProps, children: [
    /* @__PURE__ */ jsx15(
      Input,
      {
        label: "Strength",
        id: "enrichment-score",
        type: "number",
        min: 0,
        max: cap,
        value: score,
        onChange: (e) => setValue(getCappedValue(Number(e.currentTarget.value) || 0, cap)),
        css: { textAlign: "center", boxSizing: "border-box" }
      }
    ),
    /* @__PURE__ */ jsx15(
      "button",
      {
        type: "button",
        title: "Reduce enrichment count",
        onClick: () => handleCounter("decrement"),
        disabled: score === 0,
        className: "scoreCounterButton",
        css: scoreCounterMinusButtonStyles,
        children: /* @__PURE__ */ jsx15(Icon4, { icon: CgMathMinus, iconColor: "gray", size: "1.5rem" })
      }
    ),
    /* @__PURE__ */ jsx15(
      "button",
      {
        type: "button",
        title: "Increase enrichment count",
        onClick: () => handleCounter("increment"),
        className: "scoreCounterButton",
        css: scoreCounterPlusButtonStyles,
        children: /* @__PURE__ */ jsx15(Icon4, { icon: CgMathPlus, iconColor: "gray", size: "1.5rem" })
      }
    )
  ] });
};
var SelectedEnrichments = ({ list, setList, dimIndex }) => {
  const removeEnrichment = (index) => {
    setList(
      produce(list, (draft) => {
        draft.splice(index, 1);
      })
    );
  };
  const updateEnrichmentScore = (index, value) => {
    var _a;
    const cap = (_a = dimIndex[`${list[index].cat}_${list[index].key}`]) == null ? void 0 : _a.cap;
    setList(
      produce(list, (draft) => {
        draft[index].str = getCappedValue(Number(value) || 0, cap);
      })
    );
  };
  return /* @__PURE__ */ jsx15(Fragment4, { children: list.map((enrichment, index) => {
    const dimData = dimIndex[getEnrichmentVectorKey(enrichment.cat, enrichment.key)];
    if (!dimData)
      return;
    return /* @__PURE__ */ jsxs7(
      "div",
      {
        css: {
          display: "flex",
          alignItems: "center",
          gap: "var(--spacing-base)",
          backgroundColor: "var(--brand-secondary-2)",
          boxShadow: "var(--shadow-base)",
          borderRadius: "var(--rounded-base)",
          paddingInline: "var(--spacing-base)",
          marginBlock: "var(--spacing-base)"
        },
        className: "selected-enrichments",
        children: [
          /* @__PURE__ */ jsx15(
            "span",
            {
              css: { fontWeight: "var(--fw-bold)", color: dimData ? void 0 : "var(--brand-secondary-5)" },
              children: dimData ? dimData.displayName : `Enrichment '${enrichment.cat}_${enrichment.key}' is unknown`
            }
          ),
          /* @__PURE__ */ jsx15(
            "div",
            {
              css: {
                marginLeft: "auto",
                display: "flex",
                alignItems: "center",
                border: "0 solid var(--gray-400)",
                borderLeftWidth: "1px",
                borderRightWidth: "1px",
                padding: "var(--spacing-sm) var(--spacing-base)",
                flexBasis: "9rem"
              },
              children: /* @__PURE__ */ jsx15(
                Input,
                {
                  type: "text",
                  min: 0,
                  max: dimData.cap || 100,
                  title: "score",
                  value: enrichment.str,
                  css: { textAlign: "center", width: "100px" },
                  onChange: (e) => updateEnrichmentScore(index, e.currentTarget.value)
                }
              )
            }
          ),
          /* @__PURE__ */ jsx15(
            "button",
            {
              type: "button",
              title: `Delete enrichment`,
              onClick: () => removeEnrichment(index),
              css: { border: 0 },
              children: /* @__PURE__ */ jsx15(Icon4, { icon: CgCloseO, iconColor: "red", size: "1.5rem" })
            }
          )
        ]
      },
      `${enrichment.cat}-${enrichment.key}`
    );
  }) });
};

// src/components/PersonalizationCriteria/PersonalizationCriteria.tsx
import { Callout as Callout3, LoadingIndicator as LoadingIndicator3 } from "@uniformdev/design-system";
import { useState as useState5 } from "react";
import { useAsync } from "react-use";
import * as yup from "yup";

// src/utils/utils.ts
function isEnrichmentTagData(obj) {
  return Array.isArray(obj) && obj.length > 0 && obj[0].cat !== void 0;
}
function isPersonalizationCriteriaData(obj) {
  return obj.crit !== void 0;
}
function opHasRhs(op) {
  return op !== "+" && op !== "-";
}

// src/components/PersonalizationCriteria/PersonalizationCriteriaStatic.tsx
import { css as css6 } from "@emotion/react";
import { CgCloseO as CgCloseO2 } from "@react-icons/all-files/cg/CgCloseO";
import { AddListButton as AddListButton2, Callout as Callout2, Icon as Icon5, InputInlineSelect, Paragraph } from "@uniformdev/design-system";
import { produce as produce2 } from "immer";

// src/components/PersonalizationCriteria/PersonalizationCriteriaStatic.styles.ts
import { css as css5 } from "@emotion/react";
var spaceBetweenCriteriaItems = "6rem";
var criteriaItem = css5`
  position: relative;
  padding: var(--spacing-md) var(--spacing-base);
  border: 1px solid var(--gray-300);
  box-shadow: var(--shadow-base);
  background-color: white;
  border-radius: var(--rounded-base);
  margin-top: ${spaceBetweenCriteriaItems};
  display: flex;

  &:before {
    content: '';
    display: block;
    width: 1px;
    height: ${spaceBetweenCriteriaItems};
    background-color: var(--gray-300);
    position: absolute;
    top: -${spaceBetweenCriteriaItems};
    left: var(--spacing-lg);
  }

  &:first-of-type {
    margin-top: var(--spacing-md);
    &:before {
      display: none;
    }
  }
`;
var criteriaItemInner = css5`
  display: flex;
  gap: var(--spacing-base);
  flex-grow: 1;
  flex-wrap: wrap;
  margin-right: var(--spacing-base);
`;
var criteriaWrapper = css5`
  width: 100%;
  display: flex;
  align-items: stretch;
  position: relative;
`;
var criteriaOperandWrapper = css5`
  flex: 2;
  height: 52px;
  min-width: 200px;
`;
var criteriaOperatorWrapper = css5`
  flex: 1;
  min-width: 80px;
`;
var expand = css5`
  height: 100%;
  width: 100%;
`;

// src/components/PersonalizationCriteria/PersonalizationCriteriaStatic.tsx
import { jsx as jsx16, jsxs as jsxs8 } from "@emotion/react/jsx-runtime";
var PersonalizationCriteriaStatic = ({
  value,
  setValue,
  dimensions,
  onMenuOpen,
  onMenuClose,
  onAddCriteria,
  onRemoveCriteria,
  displayTitle = true,
  components,
  errors = {}
}) => {
  var _a;
  const currentValue = { crit: [], ...value };
  currentValue.crit = (_a = currentValue.crit) != null ? _a : [];
  const setOp = (op) => {
    const finalOp = op === "&" ? void 0 : op;
    setValue({
      ...currentValue,
      op: finalOp
    });
  };
  const addToList = () => {
    const newValue = {
      ...currentValue,
      crit: [...currentValue.crit, { l: "", op: ">", r: 0 }]
    };
    setValue(newValue);
    onAddCriteria == null ? void 0 : onAddCriteria(newValue);
  };
  const update = (crit, index) => {
    setValue(
      produce2(currentValue, (draft) => {
        draft.crit[index] = crit;
      })
    );
  };
  const removeFromList = (index) => {
    const newValue = produce2(currentValue, (draft) => {
      draft.crit.splice(index, 1);
    });
    const finalValue = newValue.crit.length === 0 ? null : newValue;
    setValue(finalValue);
    onRemoveCriteria == null ? void 0 : onRemoveCriteria(finalValue);
  };
  return /* @__PURE__ */ jsxs8("fieldset", { className: "personalization-criteria", children: [
    displayTitle ? (components == null ? void 0 : components.Title) ? /* @__PURE__ */ jsx16(components.Title, {}) : /* @__PURE__ */ jsx16(
      "legend",
      {
        css: {
          fontSize: "var(--fs-md)",
          fontWeight: "var(--fw-bold)"
        },
        children: "Personalize This"
      }
    ) : null,
    (components == null ? void 0 : components.CustomVariantName) ? /* @__PURE__ */ jsx16(components.CustomVariantName, {}) : null,
    !currentValue.crit.length ? /* @__PURE__ */ jsx16(Callout2, { title: "Default variant", type: "info", css: { marginBlock: "var(--spacing-base)" }, children: /* @__PURE__ */ jsx16(Paragraph, { children: 'This personalized variant has no match criteria and will be shown to any visitor that does not match any preceding variants. Ensure that default variants come last in the variant list. Personalize this variant by clicking "Add Criteria" to get started.' }) }) : /* @__PURE__ */ jsx16("div", { children: currentValue.crit.map((currentCriteria, index) => {
      var _a2, _b, _c, _d;
      const critHasLhs = ((_a2 = currentCriteria.l) == null ? void 0 : _a2.length) > 0;
      const critHasRhs = currentCriteria.op !== "+" && currentCriteria.op !== "-";
      return /* @__PURE__ */ jsxs8("div", { css: criteriaItem, "data-testid": "criteria-container", children: [
        /* @__PURE__ */ jsxs8(
          "div",
          {
            css: css6`
                    ${criteriaItemInner}/* grid-template-columns: minmax(0, 1fr) ${critHasRhs ? "minmax(0, 79px) minmax(0, 1fr)" : "minmax(0, 1fr)"} */
                  `,
            className: "criteriaItemInner",
            children: [
              /* @__PURE__ */ jsx16(
                "div",
                {
                  css: [criteriaWrapper, criteriaOperandWrapper],
                  className: "criteria-wrapper",
                  "data-testid": "select-criteria",
                  children: /* @__PURE__ */ jsx16(
                    DimensionMenu,
                    {
                      errorMessage: (_b = errors.lhs) == null ? void 0 : _b[index],
                      css: expand,
                      styles: { control: (base) => ({ ...base, height: "100%" }) },
                      dimensions: dimensions.dimensions,
                      onChange: (dim) => {
                        update({ ...currentCriteria, l: dim.dim }, index);
                      },
                      value: dimensions.dimIndex[currentCriteria.l],
                      onMenuOpen,
                      onMenuClose
                    }
                  )
                }
              ),
              /* @__PURE__ */ jsx16(
                "div",
                {
                  css: [criteriaWrapper, criteriaOperatorWrapper],
                  className: "criteria-wrapper",
                  "data-testid": "select-operator",
                  children: /* @__PURE__ */ jsx16(
                    CriteriaOperatorMenu,
                    {
                      name: `op-${index}`,
                      css: expand,
                      styles: { control: (base) => ({ ...base, height: "100%" }) },
                      value: currentCriteria.op,
                      onChange: (op) => {
                        if (op === "+" || op === "-") {
                          update({ ...currentCriteria, op, r: void 0, rDim: void 0 }, index);
                        } else {
                          update({ ...currentCriteria, op }, index);
                        }
                      },
                      onMenuOpen,
                      onMenuClose
                    }
                  )
                }
              ),
              critHasRhs ? /* @__PURE__ */ jsx16(
                "div",
                {
                  css: [criteriaWrapper, criteriaOperandWrapper],
                  className: "criteria-wrapper",
                  "data-testid": "select-match-criteria",
                  children: /* @__PURE__ */ jsx16(
                    CriteriaMatchMenu,
                    {
                      errorMessage: (_c = errors.rhs) == null ? void 0 : _c[index],
                      css: expand,
                      styles: { control: (base) => ({ ...base, height: "100%" }) },
                      criteriaMatch: currentCriteria,
                      onChange: (match) => {
                        update(match, index);
                      },
                      isDisabled: !critHasLhs,
                      dimensions,
                      onMenuOpen,
                      onMenuClose
                    }
                  )
                }
              ) : null
            ]
          }
        ),
        /* @__PURE__ */ jsx16(
          "button",
          {
            type: "button",
            onClick: () => removeFromList(index),
            title: `Delete Personalization`,
            css: { backgroundColor: "transparent", backgroundImage: "none", borderWidth: 0 },
            "data-testid": "button-delete",
            children: /* @__PURE__ */ jsx16(Icon5, { icon: CgCloseO2, iconColor: "red", size: "1.5rem" })
          }
        ),
        index > 0 ? /* @__PURE__ */ jsx16(
          "div",
          {
            className: "criteria-group-operation",
            css: {
              position: "absolute",
              top: "-4rem",
              transform: "translateX(calc(1.5rem - 50%))"
            },
            children: /* @__PURE__ */ jsx16(
              InputInlineSelect,
              {
                "data-testid": "dropdown-button-combine",
                disabled: index > 1,
                value: (_d = currentValue.op) != null ? _d : "&",
                options: [
                  { label: "AND", value: "&" },
                  { label: "OR", value: "|" }
                ],
                onChange: (v) => {
                  setOp(v.value);
                }
              }
            )
          }
        ) : null
      ] }, index);
    }) }),
    dimensions.dimensions.length === 0 ? (components == null ? void 0 : components.NoDimensionsDefined) ? /* @__PURE__ */ jsx16(components.NoDimensionsDefined, {}) : /* @__PURE__ */ jsx16(Callout2, { title: "Dimensions", type: "info", css: { marginBlock: "var(--spacing-base)" }, children: /* @__PURE__ */ jsx16("p", { children: "You do not have any dimensions configured." }) }) : /* @__PURE__ */ jsx16(
      AddListButton2,
      {
        "data-testid": "button-add-criteria",
        className: "add-more",
        buttonText: "Add Criteria",
        onButtonClick: addToList
      }
    )
  ] });
};

// src/components/PersonalizationCriteria/PersonalizationCriteria.tsx
import { jsx as jsx17, jsxs as jsxs9 } from "@emotion/react/jsx-runtime";
function convertErrorsToObj(errors) {
  const result = { crit: [] };
  errors.forEach((err) => {
    var _a;
    if (!err.path)
      return;
    const regexp = /\[(\d+)\]\.(\w+)/g;
    const matches = (_a = [...err.path.matchAll(regexp)]) == null ? void 0 : _a[0];
    if ((matches == null ? void 0 : matches.length) == 3) {
      const index = Number(matches[1]);
      const prop = matches[2];
      if (result.crit.length > index) {
        result.crit[index] = { ...result.crit[index], [prop]: err.message };
      } else {
        const variant = {};
        variant[prop] = err.message;
        result.crit.push(variant);
      }
    }
  });
  return result;
}
async function validate(value, contextData) {
  const schema = yup.object().shape(
    {
      l: yup.string().required("Please select a dimension").oneOf(
        contextData == null ? void 0 : contextData.dimensions.map((d) => d.dim),
        "Select a criteria"
      ),
      op: yup.string().required(),
      r: yup.mixed().nullable().when(["rDim", "op"], {
        is: (rDim, op) => opHasRhs(op) && !rDim,
        then: yup.string().required("Choose a score or dimension")
      }),
      rDim: yup.string().oneOf(
        contextData == null ? void 0 : contextData.dimensions.map((d) => d.dim),
        "Select a criteria"
      ).when(["r", "op"], {
        is: (r, op) => opHasRhs(op) && !r,
        then: yup.string().required("Choose a score or dimension")
      })
    },
    // wat? https://stackoverflow.com/questions/63342947/yup-cyclic-dependency-two-fields-mutually-requiring-each-other
    ["rDim", "r"]
  );
  try {
    await yup.object({
      crit: yup.array(schema)
    }).nullable().validate(value, { abortEarly: false });
  } catch (e) {
    return convertErrorsToObj(e.inner);
  }
}
var PersonalizationCriteria = ({
  contextConfig,
  value,
  setValue,
  ...staticProps
}) => {
  var _a, _b;
  const [validationError, setValidationError] = useState5(void 0);
  const { loading, result: dimensions, error } = useDimensions(contextConfig);
  useAsync(async () => {
    if (value && dimensions) {
      const err = await validate(value, dimensions);
      setValidationError(err);
    }
  }, [value, dimensions, validate]);
  if (error)
    return /* @__PURE__ */ jsx17(Callout3, { type: "danger", children: error });
  if (loading || dimensions === null)
    return /* @__PURE__ */ jsx17(LoadingIndicator3, {});
  const handleSetValue = async (value2) => {
    const err = await validate(value2, dimensions);
    setValidationError(err);
    setValue(value2);
  };
  return /* @__PURE__ */ jsx17(
    PersonalizationCriteriaStatic,
    {
      ...staticProps,
      value,
      setValue: handleSetValue,
      errors: {
        lhs: (_a = validationError == null ? void 0 : validationError.crit) == null ? void 0 : _a.map((err) => err == null ? void 0 : err.l),
        rhs: (_b = validationError == null ? void 0 : validationError.crit) == null ? void 0 : _b.map((err) => err == null ? void 0 : err.rDim)
      },
      dimensions,
      components: {
        NoDimensionsDefined: () => /* @__PURE__ */ jsx17(Callout3, { title: "Dimensions", type: "info", css: { marginBlock: "var(--spacing-base)" }, children: /* @__PURE__ */ jsxs9("p", { children: [
          "You do not have any dimensions configured. Create your first",
          " ",
          /* @__PURE__ */ jsx17(
            "a",
            {
              href: `${contextConfig.apiHost}/projects/${encodeURIComponent(
                contextConfig.projectId
              )}/personalization/signals`,
              target: "_blank",
              rel: "noopener noreferrer",
              css: { ":hover": { textDecorationLine: "underline" } },
              children: "Dimension"
            }
          )
        ] }) })
      }
    }
  );
};

// src/components/ProjectUIVersion/ProjectUIVersion.tsx
import { LoadingIndicator as LoadingIndicator4 } from "@uniformdev/design-system";
import { Fragment as Fragment5, jsx as jsx18 } from "@emotion/react/jsx-runtime";
function ProjectUIVersion({ children, versionMap, contextConfig }) {
  const { loading, result: data } = useContextData(contextConfig);
  if (loading)
    return /* @__PURE__ */ jsx18(LoadingIndicator4, {});
  if (data) {
    const Component = versionMap[data.project.ui_version];
    if (Component) {
      return /* @__PURE__ */ jsx18(Component, {});
    }
  }
  return /* @__PURE__ */ jsx18(Fragment5, { children });
}

// src/components/AbTestSelector/AbTestSelector.tsx
import { css as css7 } from "@emotion/react";
import {
  AddListButton as AddListButton3,
  Button as Button2,
  Callout as Callout4,
  Heading,
  Link,
  LoadingIndicator as LoadingIndicator5,
  ScrollableList,
  ScrollableListItem
} from "@uniformdev/design-system";
import { useState as useState7 } from "react";

// src/hooks/useABTests.ts
import { ApiClientError as ApiClientError3, CachedTestClient } from "@uniformdev/context/api";
import { useEffect as useEffect3, useState as useState6 } from "react";
function useABTests({ apiHost, apiKey, projectId }) {
  const [reload, setReload] = useState6(true);
  const [state, setState] = useState6({
    loading: false,
    notConfigured: false,
    error: null,
    result: null
  });
  useEffect3(() => {
    if (!projectId || !apiKey || !apiHost) {
      setState({ notConfigured: true, loading: false, error: null, result: null });
      return;
    }
    const runEffect = async () => {
      setState({ notConfigured: false, loading: true, error: null, result: null });
      try {
        const client = new CachedTestClient({
          projectId,
          apiKey,
          apiHost
        });
        const { tests } = await client.get();
        setState({ notConfigured: false, loading: false, error: null, result: tests });
      } catch (e) {
        let message;
        if (e instanceof ApiClientError3) {
          message = e.message;
        } else {
          message = e.toString();
        }
        setState({ notConfigured: false, loading: false, error: message, result: null });
        return;
      }
    };
    if (reload) {
      runEffect().then(() => {
        setReload(false);
      });
    }
  }, [apiHost, apiKey, projectId, reload]);
  return {
    result: state.result,
    error: state.error,
    loading: state.loading,
    notConfigured: state.notConfigured,
    doReload: () => setReload(true)
  };
}

// src/components/AbTestSelector/AbTestSelector.tsx
import { Fragment as Fragment6, jsx as jsx19, jsxs as jsxs10 } from "@emotion/react/jsx-runtime";
var addAbTestLink = css7`
  flex: 2;
  display: flex;
  width: 50%;
  align-items: center;
  font-weight: var(--fw-bold);
  color: var(--brand-primary-1);
  &:hover,
  &:focus {
    text-decoration-line: underline;
  }
`;
var AbTestList = ({ contextConfig, onSelect, value }) => {
  const { loading, result, doReload = () => {
  } } = useABTests(contextConfig);
  if (loading) {
    return /* @__PURE__ */ jsx19(LoadingIndicator5, {});
  }
  return /* @__PURE__ */ jsxs10(Fragment6, { children: [
    /* @__PURE__ */ jsx19("div", { children: /* @__PURE__ */ jsx19(Link, { text: "Refresh A/B Test list", style: { marginRight: "10px" }, onClick: () => doReload() }) }),
    !result || result.length === 0 ? /* @__PURE__ */ jsx19(NoAbTestsView, { contextConfig }) : /* @__PURE__ */ jsxs10("div", { style: { marginTop: "10px" }, children: [
      /* @__PURE__ */ jsx19(ScrollableList, { children: result.map((abTest) => {
        const { id, name } = abTest;
        return /* @__PURE__ */ jsx19(
          ScrollableListItem,
          {
            buttonText: name,
            active: id === (value == null ? void 0 : value.id),
            onClick: () => onSelect(abTest)
          },
          id
        );
      }) }),
      /* @__PURE__ */ jsx19(
        AddListButton3,
        {
          className: "add-more",
          onButtonClick: () => {
            window.open(
              `${contextConfig.apiHost}/projects/${encodeURIComponent(contextConfig.projectId)}/testing`,
              "_blank"
            );
          },
          buttonText: "Add new A/B Test"
        }
      )
    ] })
  ] });
};
var AbTestSelector = ({ value, setValue, contextConfig, loading }) => {
  var _a;
  const [showAbTests, setShowAbTests] = useState7(false);
  if (loading) {
    return /* @__PURE__ */ jsx19(LoadingIndicator5, {});
  }
  return /* @__PURE__ */ jsx19("fieldset", { className: "ab-test", children: /* @__PURE__ */ jsxs10("div", { children: [
    !showAbTests && /* @__PURE__ */ jsxs10("div", { children: [
      value && Object.keys(value).length > 0 && /* @__PURE__ */ jsx19(Heading, { level: 4, style: { marginBottom: "10px" }, children: (_a = value == null ? void 0 : value.name) != null ? _a : "Unknown test" }),
      /* @__PURE__ */ jsx19(Button2, { buttonType: "primary", style: { marginRight: "10px" }, onClick: () => setShowAbTests(true), children: "Select A/B Test" })
    ] }),
    showAbTests && /* @__PURE__ */ jsx19(
      AbTestList,
      {
        contextConfig,
        onSelect: (abTest) => {
          if ((value == null ? void 0 : value.id) === abTest.id) {
            setValue(void 0);
            setShowAbTests(true);
            return;
          }
          setValue(abTest);
          setShowAbTests(false);
        },
        value
      }
    )
  ] }) });
};
var NoAbTestsView = ({ contextConfig }) => /* @__PURE__ */ jsx19(
  Callout4,
  {
    title: "No A/B tests found in your Uniform project.",
    type: "caution",
    css: { marginBlock: "var(--spacing-base)" },
    children: /* @__PURE__ */ jsxs10("p", { children: [
      "Looks like you do not have any A/B tests created in your connected Uniform project. Start by creating your first test",
      " ",
      /* @__PURE__ */ jsx19(
        "a",
        {
          href: `${contextConfig.apiHost}/projects/${encodeURIComponent(contextConfig.projectId)}/testing`,
          target: "_blank",
          rel: "noopener noreferrer",
          css: { ":hover": { textDecorationLine: "underline" } },
          children: "here"
        }
      ),
      "."
    ] })
  }
);

// src/hooks/useValidateContextConfig.ts
import { useEffect as useEffect4, useState as useState8 } from "react";

// src/utils/validateContextConfig.ts
import { UncachedManifestClient } from "@uniformdev/context/api";
import { validate as validate2 } from "uuid";
var validateContextConfig = async (contextConfig) => {
  if (!contextConfig) {
    return { valid: false, error: new Error("contextConfig was not defined.") };
  }
  if (!contextConfig.apiHost) {
    return { valid: false, error: new Error("apiHost was not defined.") };
  }
  if (!contextConfig.apiKey) {
    return { valid: false, error: new Error("apiKey was not defined.") };
  }
  if (!validate2(contextConfig.apiKey) && !contextConfig.projectId) {
    return { valid: false, error: new Error("projectId is required when using a modern API key.") };
  }
  const client = new UncachedManifestClient({
    projectId: contextConfig.projectId,
    apiKey: contextConfig.apiKey,
    apiHost: contextConfig.apiHost
  });
  try {
    const result = await client.get({ preview: true });
    return {
      valid: true,
      result
    };
  } catch (e) {
    return { valid: false, error: e };
  }
};

// src/hooks/useValidateContextConfig.ts
var useValidateContextConfig = (contextConfig) => {
  const [state, setState] = useState8({
    validating: false,
    error: void 0
  });
  const { apiKey, apiHost, projectId } = contextConfig || {};
  useEffect4(() => {
    if (!apiKey || !apiHost) {
      return;
    }
    const runEffect = async () => {
      setState({ validating: true, error: void 0 });
      const { error, result } = await validateContextConfig({ apiHost, apiKey, projectId });
      if (error) {
        setState({ error, validating: false });
      } else {
        setState({ error: void 0, validating: false, result });
      }
    };
    runEffect();
  }, [apiHost, apiKey, projectId]);
  return {
    validating: state.validating,
    error: state.error,
    result: state.result
  };
};

// src/index.ts
export * from "@uniformdev/design-system";
export {
  AbTestSelector,
  ContextData,
  CriteriaMatchMenu,
  CriteriaOperatorMenu,
  DimensionMenu,
  DimensionValue,
  EditLink,
  EnrichmentTag,
  PersonalizationCriteria,
  PersonalizationCriteriaStatic,
  ProjectUIVersion,
  addAbTestLink,
  addEnrichmentLink,
  contextCriteriaMenuOperators,
  convertErrorsToObj,
  isEnrichmentTagData,
  isPersonalizationCriteriaData,
  opHasRhs,
  useContextConfig,
  useContextData,
  useDimensions,
  useDimensionsDataContext,
  useManifest,
  useValidateContextConfig,
  validateContextConfig
};
