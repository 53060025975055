import React, { FunctionComponent } from 'react';
import { Menu, EntryCard } from '@contentful/f36-components';
import { CustomEntityCardProps } from '@contentful/field-editor-reference';
import { entityHelpers, Entry } from '@contentful/field-editor-shared';
import {
  useDimensionsDataContext,
  contextCriteriaMenuOperators,
  Callout,
  UniformBadge,
} from '@uniformdev/context-ui';
import { personalizationCriteriaFieldId } from '../../../api/fieldIdProvider';
import { VariantMatchCriteria } from '@uniformdev/context';

const { getEntryTitle, getEntryStatus } = entityHelpers;

function GetFieldData(entry: Entry): VariantMatchCriteria {
  const data = entry.fields[personalizationCriteriaFieldId];

  if (!data) {
    // no intents tagged
    return {
      crit: [],
    };
  }
  // despite not being localizable the data have a language key, i.e. 'en-US'
  // after this intents is an array of strings of intent category names
  return data[Object.keys(data)[0]];
}

export type NotADragHandleProps = {
  isDragging?: boolean;
  entity: Entry;
  drag: React.ReactElement;
  sortableDragHandle?: CustomEntityCardProps['renderDragHandle'];
};

const NotADragHandle: FunctionComponent<NotADragHandleProps> = (props) => {
  const data = GetFieldData(props.entity as Entry);
  const dimensions = useDimensionsDataContext();

  return (
    <>
      {props.sortableDragHandle && props.sortableDragHandle({ drag: props.drag })}
      <div className="uo-plist-card-contents">
        <div className="uo-plist-card-contents__icon-wrapper">
          <UniformBadge className="uo-plist-card-contents__icon" />
          <span>Personalization Criteria</span>
        </div>
        <div className="uo-plist-card-contents__tag-list">
          {!data.crit.length ? (
            <Callout title="Default variant" type="info" className="my-4">
              <p>
                This personalized variant has no match criteria and will be shown to any visitor that does not
                match any preceding variants. Ensure that default variants come last in the variant list.
                Personalize this variant by clicking &quot;Add Criteria&quot; to get started.
              </p>
            </Callout>
          ) : (
            data.crit.map((currentCriteria, index: number) => {
              return (
                <div
                  key={index}
                  className="uo-plist-card-contents__tag-list-item uo-plist-card-contents__tag-list-item--str50"
                >
                  {index > 0 ? (
                    <span>
                      {
                        [
                          { label: 'AND', value: '&' },
                          { label: 'OR', value: '|' },
                        ].find((c) => c.value === (data?.op ?? '&'))?.label
                      }
                    </span>
                  ) : null}
                  &nbsp;
                  {dimensions!.dimensions.find((d) => d.dim === currentCriteria.l)?.displayName}&nbsp;
                  {contextCriteriaMenuOperators.find((e) => e.value === currentCriteria.op)?.name}&nbsp;
                  {currentCriteria.rDim
                    ? dimensions.dimIndex[currentCriteria.rDim]?.displayName
                    : currentCriteria.r}
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export const ContextPersonalizationListCard: (props: CustomEntityCardProps) => React.ReactElement = (
  props
) => {
  const { contentType, entity, localeCode, defaultLocaleCode } = props;

  const entry = entity as Entry;

  const title = getEntryTitle({
    entry,
    contentType,
    localeCode,
    defaultLocaleCode,
    defaultTitle: 'Untitled',
  });

  const status = getEntryStatus(props.entity?.sys);
  // ref: https://github.com/contentful/field-editors/blob/844c9c2482a90d211d485e5134e6f12b629a6b6c/packages/reference/src/entries/WrappedEntryCard/WrappedEntryCard.tsx
  return (
    <EntryCard
      href={props.entityUrl}
      as="a"
      key={entity.sys.id}
      contentType={contentType?.name}
      title={title}
      size="auto"
      status={status}
      className="draggable-custom-entry-card"
      dragHandleRender={({ isDragging, drag }) => (
        <NotADragHandle
          sortableDragHandle={props.renderDragHandle}
          drag={drag}
          isDragging={isDragging}
          entity={entity}
        />
      )}
      // dragHandleRender={props.renderDragHandle}
      withDragHandle
      actions={[
        <Menu.Item
          key="edit"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            props.onEdit && props.onEdit();
          }}
        >
          Edit
        </Menu.Item>,
        <Menu.Item
          key="remove"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            props.onRemove && props.onRemove();
          }}
        >
          Remove
        </Menu.Item>,
      ]}
      onClick={(e) => {
        e.preventDefault();
        props.onEdit && props.onEdit();
      }}
    />
  );
};
