import { TextInput, TextInputProps, FormControl } from '@contentful/f36-components';
import React from 'react';
import { useFormikContext } from 'formik';

export const FormikCtflTextField: React.FunctionComponent<
  Omit<
    TextInputProps,
    'id' | 'countCharacters' | 'onChange' | 'value' | 'validationMessage' | 'testId' | 'width' | 'textarea'
  > & { labelText: string; name: string }
> = (props) => {
  const { name, labelText, isRequired = false, ...otherProps } = props;
  const formik = useFormikContext<any>();

  return (
    <FormControl isRequired={isRequired} isInvalid={Boolean(formik.errors[name])}>
      <FormControl.Label>{labelText}</FormControl.Label>
      <TextInput
        {...otherProps}
        isRequired={isRequired}
        id={props.name}
        name={props.name}
        value={formik.values[name]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      {formik.errors[props.name] && (
        <FormControl.ValidationMessage>{formik.errors[name]?.toString()}</FormControl.ValidationMessage>
      )}
    </FormControl>
  );
};
