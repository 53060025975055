import React, { useState, useEffect } from 'react';
import { ContentType } from '@contentful/app-sdk';
import { DeleteIcon, PlusIcon } from '@contentful/f36-icons';
import {
  Paragraph,
  Button,
  SectionHeading,
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@contentful/f36-components';
import tokens from '@contentful/f36-tokens';
import { personalizedListFieldId } from '../../api/fieldIdProvider';
import { parseError } from '../../utils/parseError';
import { useAnalytics } from '@uniformdev/telemetry';
import { createClient } from 'contentful-management';
import { useFilteredContentTypes } from './hooks/useFilteredContentTypes';
import { ContentTypeSettingsProps } from './types';
import { SaveWarning } from './SaveWarning';

export const PersonalizationLists: React.FunctionComponent<ContentTypeSettingsProps> = (props) => {
  const analytics = useAnalytics();
  const {
    sdk: { dialogs: dialogApi, space: spaceApi, notifier: notifyApi, cmaAdapter, ids },
    onChange,
    types,
    appSettingsSavedIndex,
  } = props;

  const [hasChangesNeedingSave, setHasChangesNeedingSave] = useState(false);

  useEffect(() => {
    // reset message when receiving new types data
    setHasChangesNeedingSave(false);
  }, [appSettingsSavedIndex]);

  const { selected } = useFilteredContentTypes(types.items, personalizedListFieldId);

  const createList = async () => {
    dialogApi
      .openPrompt({
        message: `Please enter your desired name for the list content type. Any invalid characters (non-alphanumeric, underscore, hyphen, period) will be stripped to create the API identifier, which cannot be changed later.`,
        title: 'Create Personalization List',
      })
      .then(async (confirmed) => {
        if (!confirmed) {
          return;
        }

        const finalName = (confirmed as string).replace(/[^A-Za-z0-9-+.]+/g, '');

        if (finalName.length === 0) {
          notifyApi.error(
            'The list name contained only invalid characters. Nothing has been created, please try again.'
          );
          return;
        }

        if (finalName.length >= 64) {
          notifyApi.error(
            'The list name was too long (max 64 characters). Nothing has been created, please try again.'
          );
          return;
        }

        const listType: Omit<ContentType, 'sys' | 'description'> = {
          name: confirmed as string,
          displayField: 'name',
          fields: [
            {
              id: 'name',
              name: 'List Name',
              type: 'Symbol',
              required: true,
              localized: false,
            },
            {
              id: personalizedListFieldId,
              name: 'List Items',
              type: 'Array',
              items: {
                type: 'Link',
                linkType: 'Entry',
              },
              localized: false,
              required: false,
            },
          ],
        };

        try {
          const cma = createClient({ apiAdapter: cmaAdapter, space: ids.space }, { type: 'plain' });

          const newContentType = await cma.contentType.createWithId(
            { contentTypeId: finalName, environmentId: ids.environment, spaceId: ids.space },
            listType
          );

          await cma.contentType.publish(
            { contentTypeId: finalName, environmentId: ids.environment, spaceId: ids.space },
            newContentType
          );

          analytics.track('contentful_personalization_list_created', {
            name: listType.name,
          });
        } catch (e) {
          const message = `Error creating list content type: ${parseError(e)}`;
          notifyApi.error(message);
          return;
        }

        onChange(true);
        setHasChangesNeedingSave(true);
        notifyApi.success(`List content type ${listType.name} created`);
      });
  };

  const deleteList = async (type: ContentType) => {
    dialogApi
      .openConfirm({
        message: `Are you sure you want to remove the list type ${type.name} (${type.sys.id})? Note that you may not delete the list if it has existing entries.`,
        title: 'Remove Personalization List Type',
      })
      .then(async (confirmed) => {
        if (!confirmed) return;

        try {
          await spaceApi.deleteContentType(type.sys.id);
          analytics.track('contentful_personalization_list_deleted', {
            name: type.name,
          });
        } catch (e) {
          if (e?.code === 'BadRequest') {
            notifyApi.error(`Cannot delete ${type.name} because entries exist. Delete the entries first.`);
          } else {
            const message = `Error deleting: ${parseError(e)}`;
            notifyApi.error(message);
          }

          return;
        }

        onChange(false);
        setHasChangesNeedingSave(true);
        notifyApi.success(`List content type ${type.name} has been removed`);
      });
  };

  return (
    <Card style={{marginBottom: tokens.spacingM}}>
      <SectionHeading as="h4">Personalization Lists</SectionHeading>

      <Paragraph>
        Lists are Contentful content types that store references to other entries. These can be used to create
        sets of personalized content variants, or to create personalized lists which can show the most
        relevant items first.
      </Paragraph>

      {hasChangesNeedingSave && <SaveWarning />}

      <Button onClick={createList} startIcon={<PlusIcon />}>
        Create New
      </Button>

      <Table style={{ marginTop: '30px' }}>
        <TableHead>
          <TableRow>
            <TableCell>Personalization List content types</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selected.map((list) => (
            <TableRow key={list.sys.id}>
              <TableCell style={{ verticalAlign: 'middle' }}>
                <a
                  href={`https://app.contentful.com/spaces/${list.sys.space?.sys.id}/content_types/${list.sys.id}/fields`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {list.name}
                </a>
              </TableCell>
              <TableCell align="right">
                <Button startIcon={<DeleteIcon />} variant="primary" onClick={() => deleteList(list)}>
                  Remove
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};
