import React, { useState } from 'react';
import { AppExtensionSDK, AppState } from '@contentful/app-sdk';
import {
  Heading,
  Paragraph,
  Card,
  TextLink,
  SkeletonContainer,
  SkeletonBodyText,
  Note,
} from '@contentful/f36-components';
import tokens from '@contentful/f36-tokens';
import { useAsync } from 'react-use';
import { UniformLogo } from '@uniformdev/optimize-cms-react';
import { UniformConnectionForm, UniformConnectionFormValues } from './UniformConnectionForm';
import { OptimizeInfo } from './OptimizeInfo';
import { ContextInfo } from './ContextInfo';
import { PersonalizationCriteria } from './PersonalizationCriteria';
import type { FormikErrors, FormikValues } from 'formik';
import { useContentTypes } from './hooks/useContentTypes';
import { IntentTagging } from './IntentTagging';
import { EnrichmentTag } from './EnrichmentTag';
import { PersonalizationLists } from './PersonalizationLists';
import { useAnalytics } from '@uniformdev/telemetry';
import { ManifestGetResponse } from '@uniformdev/context/api';
import { parseQuickConnect } from '@uniformdev/context';
import { validateContextConfig, ContextConfig } from '@uniformdev/context-ui';
import { getContentTypes } from '../../api/getContentTypes';
import {
  enrichmentTagFieldId,
  intentTagFieldId,
  personalizedListFieldId,
  personalizationCriteriaFieldId,
  abTestListFieldId,
  abTestFieldId,
} from '../../api/fieldIdProvider';
import { SaveWarning } from './SaveWarning';
import { AbTestingLists } from './AbTestingLists';

interface EditorInterface {
  [key: string]: {
    controls?: { fieldId: string }[];
  };
}

interface ConfigProps {
  sdk: AppExtensionSDK;
}

export enum ConfigStates {
  Connected = 'c',
  QuickConnect = 'qc',
  AdvancedConnect = 'ac',
}

export type ConfigScreenValues = ContextConfig & { quickConnect: string };

const defaultParameters: ContextConfig = {
  apiKey: '',
  projectId: '',
  apiHost: 'https://uniform.app',
};

// live updated version of the formik API key form's values
let connectConfigFormValues: UniformConnectionFormValues = {
  isValid: false,
  values: {},
};

export default function ConfigScreen({ sdk }: ConfigProps): JSX.Element {
  const analytics = useAnalytics();

  // called when 'Save' button clicked
  sdk.app.onConfigure(async () => {
    if (!connectConfigFormValues.isValid) {
      sdk.notifier.error('Cannot save app configuration when it is not valid.');
      return false;
    }

    let contextConfig: ContextConfig = {
      apiKey: connectConfigFormValues.values.apiKey,
      apiHost: connectConfigFormValues.values.apiHost,
      projectId: connectConfigFormValues.values.projectId,
    };

    setUpdatedParameters(contextConfig);
    setConnectionWasChanged(false);

    analytics.track('contentful_app_config_saved');

    // determine the configuration to apply the app editor to content types
    const contentTypes = await getContentTypes(sdk.space);
    const editorInterfaceConfigs: EditorInterface = {};
    const updateConfig = (contentTypeId: string, fieldId: string) => {
      if (editorInterfaceConfigs[contentTypeId]) {
        editorInterfaceConfigs[contentTypeId].controls?.push({
          fieldId: fieldId,
        });
      } else {
        editorInterfaceConfigs[contentTypeId] = {
          controls: [
            {
              fieldId: fieldId,
            },
          ],
        };
      }
    };

    contentTypes.items.forEach((ct) => {
      if (ct.fields.some((f) => f.id === personalizedListFieldId)) {
        updateConfig(ct.sys.id, personalizedListFieldId);
      }
      if (ct.fields.some((f) => f.id === enrichmentTagFieldId)) {
        updateConfig(ct.sys.id, enrichmentTagFieldId);
      }
      if (ct.fields.some((f) => f.id === intentTagFieldId)) {
        updateConfig(ct.sys.id, intentTagFieldId);
      }
      if (ct.fields.some((f) => f.id === personalizationCriteriaFieldId)) {
        updateConfig(ct.sys.id, personalizationCriteriaFieldId);
      }
      if (ct.fields.some((f) => f.id === abTestFieldId)) {
        updateConfig(ct.sys.id, abTestFieldId);
      }
      if (ct.fields.some((f) => f.id === abTestListFieldId)) {
        updateConfig(ct.sys.id, abTestListFieldId);
      }
    });
    const currentState = await sdk.app.getCurrentState();

    setAppSettingsSavedIndex((prev) => prev + 1);

    // save the updated app config
    return {
      parameters: contextConfig,
      targetState: {
        EditorInterface: {
          ...currentState?.EditorInterface,
          ...editorInterfaceConfigs,
        },
      },
    };
  });

  /** Form validation function for API key form */
  const validate = async (values: FormikValues): Promise<FormikErrors<FormikValues>> => {
    const errors: Record<string, string> = {};

    if (values.apiKey || values.quickConnect) {
      let config: ContextConfig = {
        apiHost: values.apiHost,
        apiKey: values.apiKey,
        projectId: values.projectId,
      };

      if (connectionConfigMode === ConfigStates.QuickConnect && values.quickConnect) {
        try {
          config = { ...parseQuickConnect(values.quickConnect) };
        } catch (e: any) {
          analytics.track('contentful_api_key_validation_failure');
          return { quickConnect: e.toString() };
        }
      }

      if (
        value &&
        (value.initialParameters.apiKey !== config.apiKey ||
          value.initialParameters.apiHost !== config.apiHost ||
          value.initialParameters.projectId !== config.projectId)
      ) {
        setConnectionWasChanged(true);
      }

      const result = await validateContextConfig(config);
      if (result.error) {
        analytics.track('contentful_api_key_validation_failure');
        const fieldToShowError =
          connectionConfigMode === ConfigStates.QuickConnect ? 'quickConnect' : 'apiKey';
        errors[fieldToShowError] = result.error.message;

        // Handle insufficient permissions error message
        // @todo export ApiClientError from context/api for error typing
        if (
          // @ts-ignore-next-line
          result.error.statusCode === 403 ||
          // @ts-ignore-next-line
          result.error.errorMessage === 'API key does not have preview permission.'
        ) {
          errors[fieldToShowError] =
            "Your API key doesn't have sufficient permissions. Ensure both Read Drafts and Manifest Read permissions are enabled for this API key";
        } else if (result.error.message === 'Failed to fetch') {
          errors[fieldToShowError] =
            'Unable to connect to your project, please verify the correctness of your Uniform API key and Project Id.';
        }
      } else {
        analytics.track('contentful_api_key_validation_success');
        setManifest(result.result);
        setConnectionConfigMode(ConfigStates.Connected);
      }
    }

    return errors;
  };

  /** Handle changes to content type configuration */
  const handleContentTypeChange = () => {
    refreshContentTypes();
  };

  const [appSettingsSavedIndex, setAppSettingsSavedIndex] = useState(0);
  const [manifest, setManifest] = useState<ManifestGetResponse | undefined>();
  // wait for validationOnMount is finished.
  const { data: contentTypes, refresh: refreshContentTypes } = useContentTypes(sdk.space);
  const [connectionConfigMode, setConnectionConfigMode] = useState<ConfigStates>(ConfigStates.QuickConnect);
  const [updatedParameters, setUpdatedParameters] = useState<ContextConfig>();
  const [connectionWasChanged, setConnectionWasChanged] = useState<boolean>(false);

  const { loading, error, value } = useAsync(async () => {
    const initialState: AppState = (await sdk.app.getCurrentState()) ?? { EditorInterface: {} };

    const appParameters = await sdk.app.getParameters();

    let initialParameters: ContextConfig = { ...defaultParameters };

    await sdk.app.setReady();

    if (appParameters) {
      const errors = await validate(appParameters as FormikValues);

      // Only if there is app params, and they are valid - then proceed, otherwise stay with default empty values.
      if (!Object.keys(errors).length) {
        initialParameters = { ...initialParameters, ...appParameters };
      }
    }

    // Because we don't render form for already connected case - populate formFields
    // to be able to re-save configs without touching connection config
    connectConfigFormValues = { values: { ...initialParameters }, isValid: true };

    return {
      initialParameters,
      initialState,
    };
  }, []);

  if (loading) {
    return (
      <div className="uo-config form36">
        <SkeletonContainer>
          <SkeletonBodyText numberOfLines={4} />
        </SkeletonContainer>
      </div>
    );
  }

  if (error || !value?.initialParameters) {
    return (
      <div className="uo-config form36">
        <Note variant="negative">{error?.message}</Note>
      </div>
    );
  }

  const onDisconnect = () => {
    setManifest(undefined);
    setConnectionConfigMode(ConfigStates.QuickConnect);
    setUpdatedParameters(defaultParameters);
    connectConfigFormValues = {
      isValid: false,
      values: defaultParameters,
    };
  };

  return (
    <div className="uo-config form36">
      <UniformLogo />
      <Heading>Uniform for Contentful</Heading>
      <Paragraph>
        Welcome to Uniform for Contentful, the fast and usable personalization engine that helps you deliver
        great content to every one of your users.{' '}
        <TextLink
          href="https://docs.uniform.app/context/apps/contentful?utm_campaign=cms_contentful"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </TextLink>
        .
      </Paragraph>

      <Card style={{marginBottom: tokens.spacingM}}>
        {connectionConfigMode === ConfigStates.Connected && manifest && (
          <>
            {connectionWasChanged && <SaveWarning />}
            {manifest.project.ui_version === 1 ? (
              <OptimizeInfo
                config={{
                  optimizeApiHost: connectConfigFormValues.values['apiHost'],
                  optimizeApiKey: connectConfigFormValues.values['apiKey'],
                  optimizeProjectId: connectConfigFormValues.values['projectId'],
                }}
              />
            ) : (
              <ContextInfo
                manifest={manifest}
                onDisconnect={onDisconnect}
                config={{
                  apiHost: connectConfigFormValues.values.apiHost,
                  apiKey: connectConfigFormValues.values.apiKey,
                  projectId: connectConfigFormValues.values.projectId,
                }}
              />
            )}
          </>
        )}

        {connectionConfigMode !== ConfigStates.Connected && (
          <UniformConnectionForm
            updateValues={(data) => {
              // this keeps a copy of the current form state in a local var, hoisting it out so we can snag the data for CTFL to save at a top level
              connectConfigFormValues = data;

              // To keep single source of truth, lets convert quick connect to separate fields.
              if (
                connectionConfigMode === ConfigStates.QuickConnect &&
                connectConfigFormValues.values.quickConnect
              ) {
                try {
                  connectConfigFormValues.values = {
                    ...connectConfigFormValues.values,
                    ...parseQuickConnect(connectConfigFormValues.values.quickConnect),
                  };
                } catch (e) {
                  // Just ignore errors, because they will be highlighted during validation.
                }
              }
            }}
            config={{
              initialValues: {
                ...(updatedParameters ?? value?.initialParameters),
                quickConnect: '',
              },
              validate,
            }}
            connectionConfigMode={connectionConfigMode}
            setConnectionConfigMode={setConnectionConfigMode}
          ></UniformConnectionForm>
        )}
      </Card>

      {manifest && (
        <>
          {contentTypes && (
            <>
              {manifest.project.ui_version === 1 ? (
                <IntentTagging
                  sdk={sdk}
                  types={contentTypes}
                  onChange={handleContentTypeChange}
                  appSettingsSavedIndex={appSettingsSavedIndex}
                />
              ) : (
                <>
                  <EnrichmentTag
                    sdk={sdk}
                    types={contentTypes}
                    onChange={handleContentTypeChange}
                    appSettingsSavedIndex={appSettingsSavedIndex}
                  />
                  <PersonalizationCriteria
                    sdk={sdk}
                    types={contentTypes}
                    onChange={handleContentTypeChange}
                    appSettingsSavedIndex={appSettingsSavedIndex}
                  />
                </>
              )}
            </>
          )}
          {contentTypes && (
            <>
              <PersonalizationLists
                sdk={sdk}
                types={contentTypes}
                onChange={handleContentTypeChange}
                appSettingsSavedIndex={appSettingsSavedIndex}
              />
              <AbTestingLists 
                sdk={sdk}
                types={contentTypes}
                onChange={handleContentTypeChange}
                appSettingsSavedIndex={appSettingsSavedIndex}
              />
            </>
          )}
        </>
      )}
    </div>
  );
}
