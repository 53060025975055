import React from 'react';
import { Paragraph } from '@contentful/f36-components';
import { DialogExtensionSDK } from '@contentful/app-sdk';

interface DialogProps {
  sdk: DialogExtensionSDK;
}

const Dialog: React.FunctionComponent<DialogProps> = (props) => {
  return <Paragraph>Hello Dialog Component {props.sdk.ids.space}</Paragraph>;
};

export default Dialog;
