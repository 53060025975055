import React from 'react';
import { PlusIcon, EditIcon, ErrorCircleOutlineIcon } from '@contentful/f36-icons';
import { Paragraph, Button, Flex, Note } from '@contentful/f36-components';
import { ManifestGetResponse } from '@uniformdev/context/api';
import { useDimensions, ContextConfig, LoadingIndicator } from '@uniformdev/context-ui';

interface ConnectionInfoProps {
  manifest: ManifestGetResponse;
  config: ContextConfig;
  onDisconnect: () => void;
}

export const ContextInfo: React.FunctionComponent<ConnectionInfoProps> = ({
  manifest,
  config,
  onDisconnect,
}) => {
  const { loading, result, error } = useDimensions(config);

  const dimensions = result?.dimensions ?? [];
  const hasDimensions = dimensions.length > 0;

  if (error) {
    return (
      <>
        <Note variant="warning" style={{ marginBottom: '1.5rem' }}>
          Successfully connected to your Uniform project.
        </Note>
        <Paragraph>
          It is possible that you have old version of config or something wrong with keys. Please try to
          re-connect.
          {error.toString()}
        </Paragraph>
        <Button variant="negative" startIcon={<ErrorCircleOutlineIcon />} onClick={onDisconnect}>
          Disconnect
        </Button>
      </>
    );
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Note variant="positive" style={{ marginBottom: '1.5rem' }}>
        Successfully connected to your Uniform project.
      </Note>

      <Paragraph>
        <strong>Project:</strong> {manifest.project.name}
      </Paragraph>
      {hasDimensions && (
        <>
          <Paragraph>
            <strong>Dimensions:</strong> {dimensions.length}
          </Paragraph>
          <Flex justifyContent="space-between">
            <Button
              startIcon={<EditIcon />}
              variant="transparent"
              style={{ marginRight: '1rem' }}
              onClick={() =>
                window.open(`${config.apiHost}/projects/${config.projectId}/personalization/signals`)
              }
            >
              Open in Uniform
            </Button>
            <Button variant="negative" startIcon={<ErrorCircleOutlineIcon />} onClick={onDisconnect}>
              Disconnect
            </Button>
          </Flex>
        </>
      )}
      {!hasDimensions && (
        <>
          <Note style={{ marginBottom: '1.5rem' }}>
            Your Uniform project has no personalization dimensions created. In order to start using
            personalization, you need to create at least one signal or enrichment. Get started here.
          </Note>
          <Flex justifyContent="space-between">
            <Button
              startIcon={<PlusIcon />}
              style={{ marginRight: '1rem' }}
              onClick={() =>
                window.open(`${config.apiHost}/projects/${config.projectId}/personalization/signals`)
              }
            >
              Manage personalization
            </Button>
            <Button variant="negative" startIcon={<ErrorCircleOutlineIcon />} onClick={onDisconnect}>
              Disconnect
            </Button>
          </Flex>
        </>
      )}
    </>
  );
};
