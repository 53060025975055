import React from 'react';
import { MultipleEntryReferenceEditor } from '@contentful/field-editor-reference';
import { FieldProps } from '../../types/FieldProps';
import { ContextConfig, ContextData } from '@uniformdev/context-ui';
import {
  FieldEditorLoading,
  FieldEditorError,
  FieldEditorNoDimensions,
  ContextPersonalizationListCard,
} from './atoms';

export type ContextPersonalizeListEditorProps = FieldProps & { config: ContextConfig };

export const ContextPersonalizationListEditor: React.FC<ContextPersonalizeListEditorProps> = ({
  sdk,
  config,
}) => {
  return (
    <ContextData
      contextConfig={config}
      loadingComponent={FieldEditorLoading}
      errorComponent={FieldEditorError}
      noIntentsComponent={FieldEditorNoDimensions}
    >
      {/* HACK for #42 until Contentful fixes their editor - see https://github.com/contentful/field-editors/issues/415#issuecomment-699860444 */}
      <style>
        {`
.DropdownContainer__DropdownContainer___3WlJM[data-testid="add-entry-menu-container"] {
  position: static !important;
  display: inline-block !important;
  margin-top: 5px !important;
  transform: none !important;
}
      `}
      </style>
      <MultipleEntryReferenceEditor
        renderCustomCard={ContextPersonalizationListCard}
        sdk={sdk as any}
        isInitiallyDisabled={false}
        viewType="link"
        parameters={{
          instance: {
            showCreateEntityAction: true,
            showLinkEntityAction: true,
          },
        }}
        hasCardEditActions={true}
      />
    </ContextData>
  );
};
