import React from 'react';

export type StyleType = 'primary' | 'success' | 'warning' | 'error';

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  className?: string;
  styleType?: StyleType;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: string;
}

const cssMap: Record<StyleType, string> = {
  error: 'uo-btn-error',
  primary: 'uo-btn-primary',
  success: 'uo-btn-success',
  warning: 'uo-btn-warning',
};

export const Button: React.FC<ButtonProps> = ({
  styleType = 'primary',
  className,
  onClick,
  children,
  icon,
  ...props
}) => {
  const css = `${cssMap[styleType]} ${className || ''}`;

  return (
    <button className={css} onClick={onClick} {...props}>
      {children}
      {icon}
    </button>
  );
};
