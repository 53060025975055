import { PersonalizationCriteria, ContextConfig } from '@uniformdev/context-ui';
import { VariantMatchCriteria } from '@uniformdev/context';
import { FieldConnector } from '@contentful/field-editor-shared';
import { FieldProps } from '../../types/FieldProps';

const PersonalizationCriteriaCustomField = function ({
  sdk,
  config,
}: FieldProps & { config: ContextConfig }) {
  return (
    <FieldConnector<VariantMatchCriteria> field={sdk.field} isInitiallyDisabled={false}>
      {({ value, setValue }) => {
        return (
          <PersonalizationCriteria
            contextConfig={{
              projectId: config.projectId,
              apiHost: config.apiHost,
              apiKey: config.apiKey!,
            }}
            setValue={setValue}
            value={value}
            onMenuOpen={() => {
              sdk.window.stopAutoResizer();
              // With additional criteria we need to expand height more and more to fit lowest combobox
              sdk.window.updateHeight(360 + (value?.crit.length ?? 0) * 140);
            }}
            onMenuClose={() => {
              sdk.window.startAutoResizer();
            }}
            displayTitle={false}
          />
        );
      }}
    </FieldConnector>
  );
};

export default PersonalizationCriteriaCustomField;
