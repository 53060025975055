import React from 'react';
import { Paragraph } from '../../atoms';
import { DataContextErrorProps } from '@uniformdev/context-ui';

export const FieldEditorError: React.FC<DataContextErrorProps> = ({ result: { error, notConfigured } }) => {
  if (error === null || notConfigured) {
    return (
      <div className="space-y-2">
        <Paragraph>Connection to Uniform is not configured. </Paragraph>
      </div>
    );
  }

  return (
    <div className="space-y-2">
      <Paragraph>Error: {error}</Paragraph>
    </div>
  );
};
