import React, { useState, useEffect } from 'react';
import { ContentType } from '@contentful/app-sdk';
import { DeleteIcon } from '@contentful/f36-icons';
import {
  Paragraph,
  Button,
  SectionHeading,
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  Option,
} from '@contentful/f36-components';
import { intentTagFieldId } from '../../api/fieldIdProvider';
import { parseError } from '../../utils/parseError';
import { useAnalytics } from '@uniformdev/telemetry';
import { createClient } from 'contentful-management';
import { useFilteredContentTypes } from './hooks/useFilteredContentTypes';
import { ContentTypeSettingsProps } from './types';
import { SaveWarning } from './SaveWarning';

export const IntentTagging: React.FunctionComponent<ContentTypeSettingsProps> = (props) => {
  const {
    sdk: { dialogs: dialogApi, space: spaceApi, notifier: notifyApi, cmaAdapter, ids },
    onChange,
    types,
    appSettingsSavedIndex,
  } = props;

  const [hasChangesNeedingSave, setHasChangesNeedingSave] = useState(false);

  useEffect(() => {
    // reset message when receiving new types data
    setHasChangesNeedingSave(false);
  }, [appSettingsSavedIndex]);

  const cma = createClient({ apiAdapter: cmaAdapter, space: ids.space }, { type: 'plain' });
  const analytics = useAnalytics();

  const { all, selected } = useFilteredContentTypes(types.items, intentTagFieldId);

  const enableTagging = async (typeId: string) => {
    const type = all.find((normie) => normie.sys.id === typeId)!;

    const newType: ContentType = {
      ...type,
      fields: [
        ...type.fields,
        {
          id: intentTagFieldId,
          name: 'Intent Tags',
          type: 'Object',
          localized: false,
          required: false,
        },
      ],
    };

    try {
      const ct = await cma.contentType.update(
        { contentTypeId: newType.sys.id, environmentId: ids.environment, spaceId: ids.space },
        newType
      );

      await cma.contentType.publish(
        { contentTypeId: newType.sys.id, environmentId: ids.environment, spaceId: ids.space },
        ct
      );

      analytics.track('contentful_intent_tagging_enabled', {
        type: newType.name,
      });
    } catch (e) {
      const message = `Unable to enable tagging: ${parseError(e)}`;
      notifyApi.error(message);
      return;
    }

    onChange(true);
    setHasChangesNeedingSave(true);
    notifyApi.success(`${type.name} enabled for intent tagging`);
  };

  const disableTagging = async (type: ContentType) => {
    dialogApi
      .openConfirm({
        message: `Are you sure you want to remove tagging from ${type.name} (${type.sys.id})? This will cause loss of any tagging data that may have been added.`,
        title: 'Remove Intent Tagging',
      })
      .then(async (confirmed) => {
        if (!confirmed) return;

        try {
          const omittedType = {
            ...type,
            fields: type.fields.map((f) => (f.id === intentTagFieldId ? { ...f, omitted: true } : f)),
          };

          const omittedTypeResult = await spaceApi.updateContentType(omittedType);

          const deletedType = {
            ...omittedTypeResult,
            fields: type.fields.filter((field) => field.id !== intentTagFieldId),
          };

          await spaceApi.updateContentType(deletedType);
          analytics.track('contentful_intent_tagging_disabled', {
            type: type.name,
          });
        } catch (e) {
          const message = `Unable to remove tagging: ${parseError(e)}`;
          notifyApi.error(message);
          return;
        }

        onChange(false);
        setHasChangesNeedingSave(true);
        notifyApi.success(`${type.name} disabled intent tagging`);
      });
  };

  return (
    <Card marginBottom="spacingL">
      <>
        <SectionHeading as="h4">Intent Tagging</SectionHeading>
        <Paragraph>
          Taggable Contentful content types can have Uniform intent data added to them, enabling them to be
          used for personalization and behavior tracking. You should enable tagging on any content types that
          you wish to be able to act as personalized variants, or that should cause adaptations to a
          visitor&apos;s profile based on seeing that content.
        </Paragraph>
        <Paragraph>
          Enabling intent tagging will add a field to the content type to hold the intent tag data. Disabling
          tagging will conversely delete this field from the content type.
        </Paragraph>

        {hasChangesNeedingSave && <SaveWarning />}

        <Select
          id="add"
          value={''}
          onChange={(evt) => enableTagging((evt.target as HTMLSelectElement).value)}
        >
          <Option value="">Enable intent tagging on new content type...</Option>
          {all.map((normie) => (
            <Option key={normie.sys.id} value={normie.sys.id}>
              {normie.name}
            </Option>
          ))}
        </Select>

        <Table style={{ marginTop: '30px' }}>
          <TableHead>
            <TableRow>
              <TableCell>Tagging-enabled content types</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selected.map((tag) => (
              <TableRow key={tag.sys.id}>
                <TableCell style={{ verticalAlign: 'middle' }}>
                  {' '}
                  <a
                    href={`https://app.contentful.com/spaces/${tag.sys.space?.sys.id}/content_types/${tag.sys.id}/fields`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {tag.name}
                  </a>
                </TableCell>
                <TableCell align="right">
                  <Button startIcon={<DeleteIcon />} variant="primary" onClick={() => disableTagging(tag)}>
                    Remove
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>
    </Card>
  );
};
