import React from 'react';
import { Paragraph, Button } from '../../atoms';
import { DataContextErrorProps, ContextConfig } from '@uniformdev/context-ui';
import { ManifestGetResponse } from '@uniformdev/context/api';

const configure = (result: ManifestGetResponse, config: ContextConfig) => {
  window.open(`${config.apiHost!}/projects/${result.project.id}/personalization/signals`);
};

export const FieldEditorNoDimensions: React.FC<DataContextErrorProps> = ({
  result: { result },
  contextConfig,
}) => {
  return (
    <>
      <Paragraph>
        No intents have been created in Uniform for the connected site{' '}
        {(result as ManifestGetResponse)?.project.name}.
      </Paragraph>
      <Paragraph>
        <Button onClick={() => configure(result as ManifestGetResponse, contextConfig)}>
          Configure Intents in Uniform
        </Button>
      </Paragraph>
    </>
  );
};
