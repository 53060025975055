import React, { FunctionComponent } from 'react';
import { Menu, EntryCard } from '@contentful/f36-components';
import { CustomEntityCardProps } from '@contentful/field-editor-reference';
import { entityHelpers, Entry } from '@contentful/field-editor-shared';

const { getEntryTitle, getEntryStatus } = entityHelpers;

export type NotADragHandleProps = {
  isDragging?: boolean;
  entity: Entry;
  drag: React.ReactElement;
  sortableDragHandle?: CustomEntityCardProps['renderDragHandle'];
};

const NotADragHandle: FunctionComponent<NotADragHandleProps> = (props) => {
  return (
    <>
      {props.sortableDragHandle && props.sortableDragHandle({ drag: props.drag })}
    </>
  );
};

export const ContextAbTestListCard: (props: CustomEntityCardProps) => React.ReactElement = (
  props
) => {
  const { contentType, entity, localeCode, defaultLocaleCode } = props;

  const entry = entity as Entry;

  const title = getEntryTitle({
    entry,
    contentType,
    localeCode,
    defaultLocaleCode,
    defaultTitle: 'Untitled',
  });

  const status = getEntryStatus(props.entity?.sys);
  // ref: https://github.com/contentful/field-editors/blob/844c9c2482a90d211d485e5134e6f12b629a6b6c/packages/reference/src/entries/WrappedEntryCard/WrappedEntryCard.tsx
  return (
    <EntryCard
      href={props.entityUrl}
      as="a"
      key={entity.sys.id}
      contentType={contentType?.name}
      title={title}
      size="auto"
      status={status}
      className="draggable-custom-entry-card"
      dragHandleRender={({ isDragging, drag }) => (
        <NotADragHandle
          sortableDragHandle={props.renderDragHandle}
          drag={drag}
          isDragging={isDragging}
          entity={entity}
        />
      )}
      // dragHandleRender={props.renderDragHandle}
      withDragHandle
      actions={[
        <Menu.Item
          key="edit"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            props.onEdit && props.onEdit();
          }}
        >
          Edit
        </Menu.Item>,
        <Menu.Item
          key="remove"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            props.onRemove && props.onRemove();
          }}
        >
          Remove
        </Menu.Item>,
      ]}
      onClick={(e) => {
        e.preventDefault();
        props.onEdit && props.onEdit();
      }}
    />
  );
};
