import postHogPlugin from '@metro-fs/analytics-plugin-posthog';
import Analytics from 'analytics';
import { AnalyticsProvider as ProviderAlias, useAnalytics as useAnalyticsAlias } from 'use-analytics';
import { Identify } from './analytics/Identify';
import { debugPlugin } from './debugPlugin';
import googleAnalytics4Plugin from './google-analytics-4/googleAnalytics4Plugin';
import { hubSpotPlugin } from './hubspot';
const AnalyticsProvider = ProviderAlias;
const useAnalytics = useAnalyticsAlias;
export { Analytics, AnalyticsProvider, Identify, useAnalytics };
export const postHog = {
    postHogPlugin,
};
export const ga4 = {
    ga4Plugin: googleAnalytics4Plugin,
};
export const hubspot = {
    hubSpotPlugin,
};
export const debug = {
    debugPlugin,
};
